.filter {
	z-index: 90;

	&__row {
		margin-bottom: 30px;

		.checkbox:nth-child(5) ~ *:not(.show-prev) {
			display: none;
		}
	}

	h5 {
		margin-bottom: 20px;
	}

	.btn-wrap {
		display: flex;
		align-items: flex-start;
		margin-top: 30px;
	}

	.checkbox + .checkbox {
		margin-top: 10px;
	}

	.checkbox {
		&:last-child {
			margin-bottom: 0;
		}
	}

	&__popup {
		display: block;
		position: fixed;
		top: 0;
		right: auto;
		bottom: 0;
		left: 0;
		height: 100%;
		width: 100%;
		max-width: 350px;
		z-index: 900;
		padding: 20px 0;
		box-sizing: border-box;
		background: $white;
		box-shadow: 0 0 10px rgba(0,0,0,.3);
		transform: translateX(-110%);
		transition: transform 1s ease;

		&._active {

			transform: translateX(0);
		}

		.btn-style {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.btn-row {
			margin-bottom: 30px;
		}
	}

	&__scroll-container {
		display: block;
		position: relative;
		height: 100%;
		padding: 0 20px;
		box-sizing: border-box;

		.ps-scrollbar-y-rail {
			right: 8px !important;
		}
	}

	@media #{$tablet} {
		.btn-wrap {
			margin-top: 25px;
		}
	}
	@media #{$pre-mobile} {
		&__popup {
			padding-top: 70px;
		}

		.close-button {
			right: 10px;

			&::before,
			&::after {
				background: $gray-med;
				height: 4px;
				border-radius: 4px;
			}
		}

		.btn-row {
			max-width: 230px;
			margin-left: auto;
			margin-right: auto;
		}

		&__scroll-container {
			.ps-scrollbar-y-rail {
				right: 0 !important;
			}
		}
	}
}


.price-range {
	margin-bottom: 30px;

	.form {
		&__table {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

		&__col {
			width: calc(50% - 10px);
		}

		.input-style {
			&__input {
				height: 40px;
				border-radius: 5px;
			}
		}

	}



	&_input {
		margin-top: 42px;
	}

	&__title {
		color: $gray-med;
		font-size: 14px;
		font-weight: 300;
	}

	&__range-list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 8px;
	}

	&__range-list & {
		&__text {
			color: $gray-med;
			font-size: 14px;
			font-weight: 300;

			&:first-child {
				text-align: left;
			}

			&:last-child {
				text-align: right;
			}
		}
	}
}


.range {
	margin-top: 27px;

	&.ui-widget-content {
		border: none;
		height: 7px;
		background-color: #f3f3f3;
		background-image: none;
		border-radius: 0;
	}

	.ui-slider-range {
		background-color: $persian_green2;
	}

	.ui-slider-handle {
		outline: none;
		cursor: pointer;
		box-sizing: border-box;
		background-color: $black;
		border-radius: 50%;
		border: none;
		width: 17px;
		height: 17px;
		z-index: 5;

		&._up {
			z-index: 10;
		}
	}
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
	border-radius: 0;
}

.show-prev {
	padding-left: 36px;
	cursor: pointer;
	margin-top: 15px;

	&__hide {
		display: none;
	}

	&._active & {
		&__hide {
			display: block;
		}

		&__show {
			display: none;
		}
	}
}