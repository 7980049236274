.calc-form {
    background-color: $white;
    padding: 57px 0 0;

    @media #{$tablet} {
        padding: 68px 0 0;
    }
    @media #{$pre-mobile} {
        padding: 30px 0 0;
    }
}

.calculator-form {
    $form: &;

    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;

    &__col {
        &_left {
            width: 56.2%;
            padding-top: 20px;
        }

        &_right {
            width: calc(36.6% + 20px);
            position: relative;
            display: block;
        }
    }

    &__scroll-wrap {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        padding-right: 20px;
        box-sizing: border-box;
        padding-top: 20px;

        //********************
        //Дизайнер болт забил
        //Иль просто он забыл
        //Выяснять это я не буду
        //Просто вставлю это чудо
        //╰( ͡° ͜ʖ ͡° )つ──☆*:・ﾟ
        &.ps-active-y {
            &::before {
                content: '';
                display: block;
                @include absolute(0,0,auto,auto);
                width: 20px;
                height: 20px;
                background: $white;
                z-index: 3;

            }
        }
    }

    &__row {
        &._activewrap {
            z-index: 5;
            position: relative;
        }
        margin-bottom: 46px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        #{$form}__item {
            &:first-child:last-child{
                width: 100%;
            }
        }
        &_clone {
            display: none;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &_counter {
            display: flex;
            justify-content: space-between;
            align-items: center;

            #{$form}__item {
                &:nth-of-type(1) {
                    width: 65.3%;
                }

                &:nth-of-type(2) {
                    width: 31.5%;
                }
            }
        }
    }

    .select-wrapper {
        &__input,
        &__select {
            //height: 60px;
            height: auto;
            min-height: 60px;
            padding-top: 5px;
            padding-bottom: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
        }
    }

    @media #{$tablet} {
        .select-wrapper {
            &__input,
            &__select {
                height: auto;
                min-height: 50px;
            }
        }
        &__row {
            margin-bottom: 39px;
        }
    }
    @media #{$pre-mobile} {
        flex-wrap: wrap;

        .select-wrapper {
            &__input,
            &__select {
                height: 40px;
                padding: 10px 30px 10px 10px;
            }

            &__select::after {
                width: 30px;
            }
        }

        &__row {
            margin-bottom: 34px;
        }

        &__col {
            width: 100%;
        }

        &__scroll-wrap {
            position: relative;
            padding-top: 40px;
            padding-right: 0;
            height: auto;
            width: 100%;
        }

        &__row {
            &_counter {
                #{$form}__item {
                    &:nth-of-type(1) {
                        width: calc(71% - 10px);
                    }

                    &:nth-of-type(2) {
                        width: 29%;
                    }
                }
            }
        }

    }
}

.calc-checkout {
    background-color: $persian_green;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 19px 19px 23px;
    margin-top: 62px;

    &__label,
    &__total {
        color: $white;
        font-family: $rb;
        font-weight: 400;
    }

    &__label {
        font-size: 16px;
        padding-right: 80px;
        box-sizing: border-box;
        flex-shrink: 1;
    }

    &__total {
        font-size: 48px;
        white-space: nowrap;
        padding: 0 20px;
        box-sizing: border-box;
        display: flex;
        align-items: baseline;
        flex: 1 0 auto;

        &::after {
            content: attr(data-currency);
            font-family: inherit;
            font-size: inherit;
            color: inherit;
            font-weight: inherit;
            position: relative;
            display: block;
            margin-left: 10px;
        }
    }

    .btn-style {
        width: 221px;
        border-color: $white;
        padding: 0 10px;
        flex: 1 0 auto;

        &:after, &:before {
            background-color: $white;
        }
    }

    .btn-style:hover .btn-style__label {
        color: $black;
    }

    &_bth-10f {
        flex: 1 0 auto;
    }

    @media #{$tablet} {
        padding: 20px 15px;

        .btn-style {
            flex: 1 0 auto;
        }

        &__label {
            font-size: 14px;
            padding-right: 0;
        }

        &__total {
            font-size: 32px;
        }
    }

    @media #{$pre-mobile} {
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 40px;
        margin-bottom: 25px;
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;
        position: relative;

        .calc-total {
            order: 1;
            width: 100%;
            margin-bottom: 14px;

            &__text {
                line-height: 28px;
            }
        }

        .commercial-offer {
            order: 2;
            width: calc(50% - 10px);

            &__text {
                font-size: 12px;
                line-height: 1.2;
            }
        }

        .btn-style {
            order: 3;
            height: 27px;
            width: auto;
            padding: 0 6px;
            flex: 0 0 auto;

            &__label {
                font-size: 11px;
            }
        }


    }
}

.commercial-offer {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__icon {
        margin-right: 7px;
        margin-top: 3px;
        font-size: 30px;
        color: $white;
    }

    &__text {
        transition: .3s ease;
        max-width: 124px;
        color: $turquoise;
        font-family: "Roboto Slab";
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
    }

    &:hover & {
        &__text {
            color: $white;
        }
    }

    &_cart {
        justify-content: space-between;
        width: 100%;
    }

    &_cart & {
        &__icon {
            transition: .3s ease;
            margin-top: 0;
            color: $persian_green;
        }

        &__text {
            max-width: none;
            width: calc(100% - 48px);
            margin-top: 1px;
            letter-spacing: 0.2px;
            color: $persian_green;
        }
    }

    &_cart:hover & {
        &__icon,
        &__text {
            color: $turquoise;
        }
    }

    @media #{$tablet} {
        &__icon {
            font-size: 26px;
        }

        &__text {
            font-size: 14px;
        }

        &_cart {
            width: calc(100% + 68px);
            left: -34px;
            background: $grey_white;
            padding: 16px 34px;
            box-sizing: border-box;
            justify-content: flex-start;
        }

        &_cart &__text {
            width: auto;
            margin-top: 0;
            font-size: 20px;
        }

        &_cart &__icon {
            font-size: 34px;
            margin-right: 20px;
        }

    }

    @media #{$pre-mobile} {
        &_cart & {
            &__icon {
                font-size: 24px;
                margin-right: 15px;
            }

            &__text {
                font-size: 13px;
            }
        }
    }
}

.calc-total {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;


    &__col {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        &:nth-of-type(1) {
            margin-right: 15px;
        }
    }

    &__text {
        color: $white;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }

    &__number {
        color: $white;
        font-size: 30px;
        font-weight: 400;
        line-height: 26px;

        &_small {
            font-size: 16px;
        }
    }

    @media #{$tablet} {
        justify-content: flex-start;

        &__text {
            font-size: 14px;
        }

        &__number {
            font-size: 22px;

            &_small {
                font-size: 14px;
            }
        }
    }

    @media #{$pre-mobile} {
        &__text {
            font-size: 12px;
        }

        &__number {
            font-size: 22px;

            &_small {
                font-size: 14px;
            }
        }
    }
}