.catalog {
    background-color: $white;
    padding: 76px 0;

    .container {
        position: relative;
    }

    @media #{$tablet} {
        padding: 28px 0 45px
    }
}

.attention {
    position: relative;
    background-color: $lemon_cream;
    box-sizing: border-box;
    padding: 37px 40px 40px;
    margin-top: 30px;

    &__content {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
    }

    .close-button {
        right: 10px;
        top: 10px;
    }

    &__text {
        margin-top: 6px;
        color: $black;
        font-size: 16px;
    }

    .btn-style {
        max-width: 141px;
        margin-top: 25px;
    }

    &_info {
        text-align: left;
    }

    &_info & {
        &__content {
            position: relative;

            &::before {
                content: '\f05a';
                font-family: $fa;
                font-size: 24px;

                @include absolute(-3px, auto, auto, -40px);

                color: $black;
                opacity: .5;
                line-height: 1;
            }
        }
    }

    @media #{$tablet} {
        //padding: 35px 60px 35px;
        padding: 20px 60px 20px;

        h4 {
            font-size: 16px;
        }

        &__text {
            margin-top: 0;
            font-size: 14px;
            line-height: 1.8;
        }

        .btn-style {
            margin-top: 16px;
        }

        &_info & {
            &__content {
                &::before {
                    top: 0;
                }
            }
        }
    }

	@media #{$pre-mobile} {
        &:not(.attention_info) {
            width: calc(100% + 20px);
            left: -10px;
            padding: 25px 10px 25px;
        }


		.btn-style {
			max-width: 105px;
		}

		&__text {
			margin-top: 14px;
			font-size: 12px;
			line-height: 1.5;
		}

        &_info {
            width: calc(100% + 20px);
            left: -10px;
            position: relative;
            padding: 3px 40px 17px;
            margin-top: 20px;
        }

        &_info & {
            &__content {
                &::before {
                    font-size: 20px;
                    left: -30px;
                }
            }
        }
	}
}

.catalog-list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    margin-top: 30px;

    .item-card {
        transform: scale(0.9);
        opacity: 0;
        transition: none;
    }

    &._animate {
        .item-card {
            transition: all .3s ease;
            transform: scale(1);
            opacity: 1;

            @for $i from 1 through 10 {
                &:nth-child(#{$i + "n"}) {
                    transition-delay: #{($i - 1) * 100 + ms} !important;
                }
            }
        }
    }

    &_slider {
        flex-wrap: nowrap;
        @media (max-width:440px) {
            width:100% !important;
        }
        .slick-list {
            margin: 0 -10px;
            @media (max-width:1000px) {
                max-width:643px;
                margin-left:auto;
                margin-right:auto;
            }
            @media (max-width:751px) {
                max-width:307px;
                margin-left:auto;
                margin-right:auto;
            }
             @media (max-width:440px) {
                max-width: 280px;
            }


        }
        .slick-track {
            display: flex;
            align-items: stretch;
        }

        .item-card {
            width: 307px;
            transform: scale(1);
            opacity: 1;
            margin: 0 10px !important;
            float: none;
            height: auto;
            @media (max-width:440px) {
                width: 280px;
            }

        }
        .slick-arrow {
            @media (max-width:440px) {
                top:100%;
                transform:translate(0,0);
                &.slick-prev {
                    left:50% !important;
                    transform: translate(-60px,0)
                }
                &.slick-next {
                    right:50% !important;
                    transform: translate(60px,0)
                }

            }
        }
    }
}

.item-card {
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 32%;
    padding: 8px 20px 103px;
    margin-bottom: 20px;

    &:nth-of-type(3n+2) {
        margin: 0 2% 20px;
    }

    &:before {
        content: "";
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        background-color: rgba(0, 0, 0, 0.04);
        transition: .3s ease;
        width: 100%;
        height: 100%;
    }

    &:hover {
        &:before {
            background-color: rgba(0, 0, 0, 0.07);
        }
    }

    &__art {
        font-size: 14px;
        font-weight: 300;
        text-transform: uppercase;
    }

    &__img-wrapper {
        z-index: 0;
        position: relative;
        display: block;
    }

    &__mod {
        z-index: 2;
        position: absolute;
        right: 32px;
        top: -10px;
        display: inline-block;
        vertical-align: middle;
        padding: 14px 10px 3px;

        &:before {
            content: "";
            position: absolute;
            left: 2px;
            top: 0;
            display: block;
            transform: skewX(-37deg);
            width: 64px;
            height: 100%;
        }

        &._new:before {
            background-color: $blue;
        }

        &._used:before {
            background-color: $orange;
        }
    }

    &__mod-text {
        position: relative;
        color: $white;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }

    &__img-wrapper {
        width: 100%;
        height: 206px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__img {
        max-width: 100%;
        max-height: 100%;

        @include object-fit(contain, center);

        &_default {
            position: absolute;
            left: 50%;
            top: 50%;
            object-fit: none;
            transform: translate(-50%, -50%);
            width: auto;
            height: auto;
        }
    }

    &__content {
        z-index: 2;
        position: relative;
    }

    &__name {
        display: block;
        letter-spacing: 0.2px;
        min-height: 48px;
        line-height: 1.3;
    }

    &__price-block {
        z-index: 3;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $grey_white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        padding: 17px 9px 18px 21px;

        .btn-style {
            width: 166px;
        }
    }

    &__price {
        color: $black;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15px;
    }

    @media #{$tablet} {
        padding: 10px 20px 80px;
        margin-bottom: 25px;
        margin-top: 25px;

        &:nth-of-type(3n+2) {
            margin: 25px 2% 25px;
        }

        &__price-block {
            padding: 18px 10px 18px 10px;

            .btn-style {
                height: 30px;
                width: auto;
                padding: 0 4px;

                &__label {
                    font-size: 12px;
                }
            }
        }

        &__price {
            font-size: 12px;
        }

        &__img-wrapper {
            display: flex;
            align-items: flex-end;
            height: 105px;
            margin-bottom: 17px;
        }

        &__img {
            width: auto;
            height: auto;

            &_default {
                top: auto;
                transform: translate(-50%, 0);
            }
        }
    }

    @media #{$tablet} {
        &__price_simple {
            flex: 1 0 auto;
            text-align: center;
            font-size: 16px;
        }

        &__name {
            font-size: 14px;
        }
    }

    @media #{$pre-mobile} {
        padding: 10px 20px 70px;
		width: calc(100% - 40px);
		margin: 0 auto 40px !important;

		&:last-child {
			margin-bottom: 0 !important;
		}

        &__name {
            min-height: 35px;
        }

        &__price-block {
            padding: 14px 10px;
        }
    }
}

.availability {
    color: $black;
    font-size: 14px;
    font-weight: 300;

    &:before {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 3px;
        margin-right: 10px;
        font-family: "FontAwesome";
        font-size: 16px;
        font-weight: 400;
    }

    &._stock:before {
        content: "\f058";
        color: $black;
    }

    &._transit:before {
        content: "\f013";
        color: #7e7e7e;
    }
}

.item-characteristics {
    margin-top: 10px;

    &__item {
        color: $black;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
    }
}

.catalog-more {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $grey_white;
    cursor: pointer;
    height: 60px;
    margin-top: 11px;
    transition: background-color .3s ease;

    &__text {
        color: $grey_dark;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        text-align: center;
        transition: color .3s ease;
    }

    &:hover {
        background-color: $persian_green;
    }
    &:hover & {
        &__text {
            color: $white;
        }
    }

    @media #{$tablet} {
        height: 50px;
        margin-top: 0;
    }
    @media #{$pre-mobile} {
		width: calc(100% - 40px);
		margin: 20px auto 0;

		&__text {
			font-size: 14px;
		}
    }
}

.catalog-nav {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 60px;
    margin-top: 45px;

    &__item {
        position: relative;
        width: 25%;
        height: 100%;

        &:first-child,
        &:nth-of-type(3) {
            &:before {
                content: none;
            }
        }

        &:before {
            content: "";
            z-index: 2;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            background-color: $grey_light;
            width: 2px;
            height: 26px;
        }

        &_hidden {
            display: none;
        }
    }

    .select-wrapper {
        background-color: transparent;
        height: 100%;

        &__select {
            height: 100%;

            &:after {
                content: "\f078";
                padding-top: 0;
                color: $persian_green;
                font-size: 12px;
                font-weight: 400;
            }
        }

        &__placeholder {
            top: 23px;
            color: $grey_dark;
            font-size: 16px;
            font-weight: 700;
            line-height: 1;
            text-transform: uppercase;
            letter-spacing: 0.8px;
        }
    }

    @media #{$tablet} {
        height: 44px;
        margin-top: 34px;


        .select-wrapper {
            &__select {
                padding: 15px;

                &::after {
                    width: 24px;
                }

                &:focus,
                &._active,
                &._selected {
                    ~ .select-wrapper__placeholder {
                        font-size: 11px;
                    }
                }
            }
            &__placeholder {
                font-size: 14px;
                left: 15px;
            }
        }
    }

    @media #{$pre-mobile} {
        flex-wrap: wrap;
        height: auto;
		margin-top: 18px;
		width: calc(100% + 20px);
		left: -10px;
        position: relative;


        .select-wrapper {
			width: 163px;

            &__placeholder {
                font-size: 12px;
            }

			&__inner {
				.option {
					font-size: 12px;
				}
			}

			&__select {
				span {
					font-size: 12px;
				}
			}
        }

        &__item {
            width: 100%;
            height: 40px;

            &_checkbox-wrap {
                width: 50%;
				margin-bottom: 20px;
            }

            &:nth-of-type(4) {
                &:before {
                    content: none;
                }
            }
        }
    }

    .select-wrapper {
        &:hover {
            .select-wrapper__placeholder {
                color: $persian_green;
            }
        }
    }
}

.checkbox-style-nav {
    position: relative;
    height: 100%;

    &_grey {
        background-color: #eeeeee;
    }

    &_price & {
        &__label {
            padding-right: 25px;

            &:after {
                content: "\f160";
                position: absolute;
                right: 22px;
                top: 50%;
                transform: translateY(-50%);
                color: $persian_green;
                font-family: "FontAwesome";
                font-size: 14px;
                font-weight: 400;
            }
        }
    }

    &__input {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        opacity: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
    }

    &__label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        height: 100%;
        color: $grey_dark;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        transition: color .3s ease;
    }



    &:hover &__label {
        color: $persian_green;
    }


    @media #{$tablet} {
        &__label {
            font-size: 14px;
        }
    }
    @media #{$pre-mobile} {
        width: 223px;

        &_grey {
            width: 100%;
        }

        &__label {
            font-size: 12px;
            padding: 15px;
            box-sizing: border-box;
            text-align: left;
            align-items: flex-start;

            br {
                display: none;
            }
        }

        &_grey &__label {
            align-items: center;
        }
    }
}