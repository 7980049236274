.cart {
	position: relative;
	width: 100%;
	overflow: hidden;

	.calc-checkout {
		margin-top: 40px;
	}

	@media #{$pre-mobile} {
		.calc-checkout {
			margin-top: 15px;

			&__total {
				justify-content: center;
				margin-bottom: 20px;
			}

			&__label {
				font-size: 12px;
				line-height: 1.2;
				text-align: center;
			}

			&__total {
				margin-bottom: 10px;
				margin-top: 10px;
			}

			.btn-style {
				height: 36px;
				margin: auto;

				&__label {
					font-size: 12px;
				}
			}
		}

		.content-tabs-nav,
		.tabs-label {
			width: 170px;
			margin: auto;
		}

		.tabs-label {
			margin-bottom: 15px;
		}

		.content-tabs-nav {
			flex-direction: column;
			height: auto;

			&__item {
				width: 100%;
				height: 35px;
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			&__label {
				width: 100%;
				justify-content: flex-start;
				font-size: 10px;

				svg {
					margin-right: 10px;
					flex: 0 0 auto;
				}
			}
		}
	}
}

.products-list {
	&__item {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		margin-bottom: 30px;
	}

	&__title {
		color: $persian_green;
		font-weight: 400;
	}

	&__subtitle {
		color: $gray-med;
		line-height: 1;
	}

	&__container {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 160px;
		box-sizing: border-box;
		padding: 20px 20px 20px 40px;
		font-size: 16px;
		position: relative;
		cursor: pointer;

		&::before {
			content: "";
			@include absolute;
			display: block;
			background-color: rgba(0,0,0,0.04);
			width: 100%;
			height: 100%;
			z-index: 2;
		}

		&:hover {
			&::before {
				background-color: rgba(0,0,0,0.07);
			}
		}
	}

	&__col {
		width: calc(50% - 10px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		z-index: 3;


		&_img {
			width: calc(100% / 12 * 4 - 16px);
			z-index: 1;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		&_title {
			//width: calc(100% / 12 * 4 - 16px);
			width: calc(100% / 12 * 4 + 18px);

			& > * {
				width: 100%;
			}
		}

		&_count {
			//width: calc(100% / 12 - 16px);
			width: 90px;
			//flex: 1 0 auto;
			align-items: center;
		}


		&_price {
			min-width: calc(100% / 12 * 2 - 16px);
			align-items: flex-end;
			font-family: $rb;
			font-weight: 400;
			font-size: 24px;
			width: auto;
			flex: 1 0 auto;
		}


		&_close {
			width: calc(100% / 12 - 16px);
			align-items: flex-end;

			.close-button {
				position: relative;
			}
		}
	}


	&__inner {
		position: relative;
		width: 100%;
		padding: 40px;
		background-color: rgba(0,0,0,0.04);
		box-sizing: border-box;
		border-top: 1px solid $grey_horse;
		display: none;
	}

	&__table {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	&__inner &__col {
		justify-content: flex-start;

		&:nth-child(even) {
			padding-left: 20px;
			box-sizing: border-box;
		}
	}

	&__row {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		align-items: baseline;
		margin-bottom: 10px;

		label, p {
			font-size: 12px;
			flex-shrink: 0;
			font-weight: 400;
		}

		label {
			margin: 0;
			color: $grey_dark;
			max-width: 150px;
		}

		.dots {
			width: 100%;
			border-bottom: 1px dotted #838383;
		}

		p {
			max-width: 230px;
			width: 50%;
			padding-left: 10px;
			box-sizing: border-box;
		}
	}


	@media #{$tablet} {
		&__table {
			flex-direction: column;
			box-sizing: border-box;
		}

		&__title {
			margin-bottom: 5px;
		}

		&__title,
		&__subtitle {
			font-size: 14px;
		}

		&__inner &__col {
			width: 100%;

			&:nth-child(even) {
				padding-left: 0;
			}
		}

		&__container {
			flex-wrap: wrap;
			padding: 40px 20px;
		}

		&__img {
			//width: calc(100% / 12 * 4 - 16px);
			margin: auto;
			display: block;
		}

		&__col {
			&_img {
				margin-bottom: 30px;
				width: 100%;
				order: 1;
			}
			&_title {
				padding-left: 20px;
				width: 50%;
				order: 3;
			}

			&_count {
				order: 2;
			}


			&_price {
				order: 4;
				font-size: 20px;
			}


			&_close {
				order: 5;
			}
		}
	}

	@media #{$pre-mobile} {
		&__container {
			padding: 30px 30px;
		}

		&__item {
			position: relative;
			width: calc(100% + 20px);
			left: -10px;
		}

		&__col {
			&_img {
				padding: 0 35px;
				box-sizing: border-box;
			}
			&_title {
				order: 2;
				padding: 0;
				width: 100%;
				text-align: center;
				align-items: center;
				margin-bottom: 10px;
			}

			&_count {
				order: 3;
			}


			&_price {
				order: 4;
			}


			&_close {
				width: 0;
				height: 0;
				position: static;
				order: 5;

				.close-button {
					position: absolute;
					bottom: auto;
					left: auto;
					top: 5px;
					right: 5px;
				}
			}
		}

		&__inner {
			padding: 30px 10px;
		}
	}
}

.total {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	background: $smoke-dark;
	min-height: 64px;
	padding: 10px 60px;
	box-sizing: border-box;

	&__label {
		margin-right: 30px;
		font-size: 16px;
	}

	&__val {
		font-family: $rb;
		font-size: 30px;
		font-weight: 400;
		line-height: 1;

	}

	@media #{$tablet} {
		padding: 10px 45px;

		&__label {
			font-size: 14px;
		}

		&__val {
			font-size: 24px;

		}
	}

	@media #{$pre-mobile} {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 10px 20px;
		width: calc(100% + 20px);
		left: -10px;
		position: relative;

		&__label {
			margin: 0 0 10px;
			text-align: center;
		}
	}
}

.content-tabs-wrap {
	overflow: hidden;


	.form {
		margin-top: 40px;
	}


	.label {
		margin-bottom: 20px;

		& + .form {
			margin-top: 0;
		}
	}

	&__content-tabs-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		z-index: 2;
		overflow: hidden;
		position: relative;
	}

	&__container {
		@include absolute(0,0,auto,0);
		display: block;
		transform: translateY(-100%);
		transition: opacity .3s ease, transform .5s ease;
		z-index: 1;
		width: 100%;
		height: auto;
		opacity: 0;

		&._active {
			position: relative;
			transform: translateY(0);
			z-index: 2;
			opacity: 1;
		}
	}

	@media #{$mobile} {
		width: calc(100% + 20px);
		margin-left: -10px;
		margin-right: -10px;
		position: relative;

		&__container {
			padding: 0 10px;
			box-sizing: border-box;
		}
	}

	@media #{$pre-mobile} {
		.form {
			margin-top: 20px;
		}
	}
}

.cart-counter {
	display: flex;
	align-items: center;
	justify-content: center;

	.arr-btn {
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&::before {
			display: block;
			position: relative;
			font-family: $fa;
			font-size: 24px;
			line-height: 1;
			color: $persian_green;
			font-weight: 800;
		}

		&_left {
			&::before {
				content: '\f104';
			}
		}

		&_right {
			&::before {
				content: '\f105';
			}
		}
	}

	.number {
		width: 30px;
		text-align: center;
		background: transparent;
		font-family: $rb;
		font-weight: 400;
		font-size: 24px;

	}

	@media #{$tablet} {
		.number {
			font-size: 21px;

		}
	}
}

.content-tabs-nav {
	position: relative;
	display: flex;
	height: 60px;
	z-index: 3;

	&__input {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		display: block;
		box-sizing: border-box;
		border-radius: 50%;
		opacity: 0;
		cursor: pointer;
	}

	&__item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(100% / 3);
		margin: 0;
		box-sizing: border-box;
		background: $smoke-med;
		transition: background .3s ease, opacity .3s ease;

		&:hover {
			background: $grey_light;
		}

		&:not(._active) {
			cursor: pointer;
		}

		@media #{$pre-mobile-plus} {
			&:not(._active) + &:not(._active) {
				&::before {
					content: "";
					z-index: 2;
					position: absolute;
					right: auto;
					left: -1px;
					top: 50%;
					transform: translateY(-50%);
					display: block;
					background-color: $grey_light;
					width: 2px;
					height: 26px;
				}
			}
		}

		&._active {
			background: $persian_green;

			.content-tabs-nav__label {
				color: $white;

				svg {
					fill: $white;
				}
			}

			.content-tabs-nav__input {
				cursor: default;
			}
		}
	}

	&__input {

	}

	&__label {
		color: gray;
		font-size: 16px;
		font-weight: 700;
		line-height: 1;
		text-transform: uppercase;
		letter-spacing: 0.8px;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;

		svg {
			width: 40px;
			height: 30px;
			fill: $persian_green;
			margin-right: 20px;
			flex: 1 0 auto;
		}

		&_icon {
			padding-left: 60px;
			box-sizing: border-box;

			&::before {
				content: '';
				@include absolute(0,auto);
				width: 30px;
				height: 30px;
			}
		}

		&_placeholder {
			background: url('images/dvg/placeholder.svg')center center no-repeat;
			background-size: contain;
		}

		&_courier {
			background: url('images/dvg/courier.svg')center center no-repeat;
			background-size: contain
		}

		&_post {
			background: url('images/dvg/placeholder.svg')center center no-repeat;
			background-size: contain
		}
	}

	@media #{$tablet} {
		height: 50px;

		&__label {
			font-size: 12px;
			font-weight: 600;
			line-height: 1.3;

			svg {
				width: 30px;
				height: 23px;
			}
		}
	}

	@media #{$pre-mobile} {
		height: 40px;

		&__item {
			padding: 0 5px;
		}
	}
}



.fl, .ul {
	display: none;
}

._fl .fl {
	display: block;
}

._ul .ul {
	display: block;
}
