$white: #fff;
$black: #2b2a29;
$black_light: #4c4c4c;
$gray: gray;
$grey_dark: #808080;
$triple_nine: #999;
$grey_med: #b2b2b2;
$grey_light: #dadada;
$grey_horse: #e5e5e5;
$grey_white: #ededed;
$grey_bg: #f6f6f6;
$smoke: #f3f3f3;
$smoke-med: #eeeeee;
$smoke-dark: #d8d8d8;
$radio: #ececec;
$input-gray: #7f7f7f;
$gray-med: #9a9a9a;
$silver: #f4f4f4;

$green_pine: #007b75;
$persian_green: #00a79f;
$persian_green2: #18a8a0;
$turquoise: #30d5c8;

$blue: #3089d5;
$orange: #f5b14f;
$lemon_cream: #fff7c3;
$red: #ff8080;