.characteristics-block {
    background-color: $white;
    padding: 59px 0 30px;

    &__title {
        text-align: center;
        letter-spacing: 0.15px;
    }

    @media #{$pre-mobile} {
        padding-top: 38px;
        padding-bottom: 0;
    }
}

.characteristics-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 45px 0 20px;

    > div {
        box-sizing: border-box;
        width: 49%;
        margin-bottom: 35px;
        padding-right: 3%;

        &:nth-last-child(-n+2) {
            margin-bottom: 0;
        }
    }

    h5 {
        color: $black;
        letter-spacing: .2px;
    }

    ul {
        margin-top: 16px;
    }

    li {
        color: $black;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: .1px;

        &:before {
            content: "\f111";
            display: inline-block;
            vertical-align: middle;
            font-family: $fa;
            font-size: 3px;
            line-height: 1;
            margin: 0 5px 3px 2px;
        }
    }

    @media #{$pre-mobile} {
        padding-top: 25px;

        h5 {
            font-size: 14px;
        }

        li {
            font-size: 12px;
        }

        &>div {
            width: 100%;
            padding-right: 0;
        }
    }
}