.pros {
	padding-bottom: 60px;

	.items-list {
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 90px;

		&__item {
			width: calc(100% / 3 - 40px / 3);
			margin-bottom: 70px;

			@for $i from 1 through 10 {
				&:nth-child(#{$i}) {

					.bg-lines {
						animation-delay: #{(($i - 1) * 100) + random(100) + ms} !important;

						&::before {
							animation-delay: #{(($i - 1) * 100) + random(100) + ms} !important;
						}

						&::after {
							animation-delay: #{(($i - 1) * 100) + random(100) + ms} !important;
						}
					}
				}
			}

			&:hover {
				.bg-lines {
					animation-duration: 1s;
				}
			}
		}

		&__img-wrap {
			width: 90px;
			height: 90px;
			margin-bottom: 35px;
			position: relative;
		}

		&__img {
			position: relative;
			display: block;
			z-index: 2;
		}

		.bg-lines {
			z-index: 1;
		}

		&__title {
			margin-bottom: 15px;
		}

		&__description {
			display: block;
			position: relative;
			width: 100%;
			max-width: 270px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@media #{$tablet} {
		.items-list {
			margin-top: 60px;

			&__img-wrap {
				width: 70px;
				height: 70px;
			}
		}
	}
	@media #{$pre-mobile} {
		.items-list {
			margin-top: 40px;

			&__img-wrap {
				margin-bottom: 27px;
			}

			&__item {
				width: 100%;
				margin-bottom: 45px;
			}

			&__text {
				font-size: 12px;
				line-height: 1.5;
			}
		}

	}
}