.header {
    background-color: $white;

    &__top {
        background-color: $black;
        padding: 6px 0;

        .btn-style {
            width: 170px;
        }
    }

    &__middle {
        padding: 15px 0 13px;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .search-button {
            color:  $persian_green;
            height: 100%;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1px;
            font-size: 20px;
        }
    }

    &__bottom {
        border-top: 1px solid $grey_horse;
    }

    &__top-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 860px;
        margin-left: auto;

        @media #{$tablet} {
            align-items: baseline;
        }
    }

    &__phone {
        transition: .3s ease;
        margin-bottom: 4px;
        color: $white;
        font-size: 18px;

        &:hover {
            color: $persian_green;
        }

    }

    &__schedule {
        margin-bottom: 8px;
        color: $white;
        font-size: 14px;
        font-weight: 300;
    }


    @media #{$tablet} {
        &__top {
            height: 46px;
            display: flex;
            align-items: center;

            .btn-style {
                width: auto;
                height: auto;
                padding: 0 8px;

                .btn-style__label {
                    font-size: 12px;
                }
            }
        }

        &__phone {
            margin-bottom: 0;
            align-self: center;
        }

        &__schedule {
            margin-bottom: 0;
            font-size: 12px;
        }
    }

    @media #{$pre-mobile} {
        position: fixed;
        top: 0;
        right: 0;
        bottom: auto;
        left: 0;
        width: 100%;
        height: 48px;
        z-index: 990;

        &__middle {
            position: relative;
            padding: 0;
            height: 48px;
            background: $black;
            z-index: 990;

            .container {
                height: 100%;
                position: relative;
                justify-content: flex-end;
            }
        }

        &__bottom {
            position: fixed;
            top: 48px;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 48px);
            transform: translateY(-150%);
            z-index: 980;
            background: $black;
            border: none;
            transition: transform .3s ease, opacity .3s ease;
            opacity: 0;

            &._active {
                transform: translateY(0);
                opacity: 1;
            }

            .container {
                height: 100%;
            }
        }
    }
}

.mail-link {
    transition: .3s ease;
    margin-bottom: 6px;
    color: $turquoise;
    font-size: 14px;
    font-weight: 300;

    &__icon {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        margin-right: 7px;
    }

    &:hover {
        color: $white;
    }

    @media #{$tablet} {
        font-size: 12px;
        margin-bottom: 0;
    }
}

.icq-link {
    transition: .3s ease;
    margin-bottom: 6px;
    color: $white;
    font-size: 14px;
    font-weight: 300;

    &__icon {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        margin-right: 7px;
    }

    &:hover {
        color: $persian_green;
    }

    @media #{$tablet} {
        font-size: 12px;
        margin-bottom: 0;
    }
}

.header-logo {
    display: block;
    width: 306px;
    height: 58px;
    margin-top: 3px;
    background: url('../images/logo/logo.svg') no-repeat left center;
    background-size: contain;

    &__img {
        display: block;
        width: 100%;
        height: 100%;
    }

    @media #{$tablet} {
        margin-top: 0;
    }
    @media #{$pre-mobile} {
        background: url('../images/logo/logo-white-big.svg') no-repeat left center;
        width: 142px;
        height: 30px;
        margin-right: auto;
    }
}

.header-form {
    background-color: $grey_white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 420px;
    height: 47px;

    .input-style,
    .input-style__input {
        height: 100%;
    }

    .input-style {
        width: calc(100% - 60px);
    }

    &__submit {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        background-color: transparent;
        height: 100%;
        width: 51px;
        text-align: center;

        &:before {
            transition: .3s ease;
            margin-bottom: 3px;
            font-size: 19px;
            color: $persian_green;
        }

        &:hover {
            &:before {
                color: $black;
            }
        }
    }

    @media #{$tablet} {
        margin: 0 20px;
    }

    @media #{$pre-mobile} {
        @include absolute(0,0,auto);
        width: 100%;
        height: 100%;
        transform: translateY(-100%);
        transition: transform .3s ease;
        z-index: 900;
        border-bottom: 1px solid $persian_green;
        box-sizing: border-box;

        .close-button {
            width: 50px;
            height: 48px;
            background: $persian_green;

            &::before,
            &::after {
                background: $white;
            }
        }

        &__submit {
            margin-right: 50px;
        }

        &._active {
            transform: translateY(0);
        }
    }
}

.basket {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 144px;
    margin-top: 7px;

    &__icon {
        margin-right: 11px;
        margin-bottom: 4px;
        color: $persian_green;
        font-size: 25px;
    }

    &__text-wrapper {
        text-align: left;
    }

    &__price,
    &_sum {
        font-size: 16px;
        line-height: 1;
    }

    &__price {
        font-weight: 700;
    }

    &__sum {
        font-weight: 400;
        letter-spacing: -1.1px;
    }

    @media #{$tablet} {
        min-width: 1px;
        flex: 1 0 auto;
        margin-top: 0;

        &__icon {
            font-size: 22px;
        }

        &__price,
        &__sum {
            font-size: 14px;
        }
    }

    @media #{$pre-mobile} {
        flex: 0 0 auto;
        height: 100%;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2px;

        &__icon {
            margin: 0;
            font-size: 26px;
        }
    }
}



.nav {
    $nav: &;

    @media #{$pre-mobile-plus} {
        position: relative;
        width: 100%;
        max-width: 782px;
        padding: 14px 0 15px;

        &__nav-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }


        &__item {
            position: static;
            padding-left: 0;

            &:before {
                content: none;
            }
        }

        &__link {
            transition: .3s ease;
            cursor: pointer;
            color: #989898;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: -0.6px;

            &._hover,
            &:hover {
                color: $black;

                &~.category-list {
                    transform: translateX(0);
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }


        .nav-btn {
            padding: 0 12px;

            &._hover,
            &:hover {

                &~.category-list {
                    transform: translateX(0);
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }

    @media #{$pre-mobile} {
        padding-top: 40px;
        padding-bottom: 60px;
        padding-left: 40px;
        padding-right: 20px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;

        .nav-btn {
            padding-bottom: 8px;

            &._have-inner {
                @include have-inner;
            }
        }

        &__item {
            color: $white;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 700;
            padding-top: 17px;
        }

        &__link {
            letter-spacing: .8px;
            padding-bottom: 8px;
            display: flex;

            &._have-inner {
                @include have-inner;
            }
        }
    }



    &_footer {
        max-width: calc(100% - 246px);

        #{$nav}__link {
            color: $triple_nine;

            &:hover {
                color: #feffff;
            }
        }

        .btn-style {
            border-color: #4d4d4d;

            &:before,
            &:after {
                background-color: #4d4d4d;
            }

            &:hover {
                .btn-style__label {
                    color: $white;
                }
            }
        }

        .btn-style__label {
            color: $turquoise;
        }

        @media #{$tablet} {
            max-width: calc(100% - 125px);

            #{$nav}__link {
                font-size: 12px;
                letter-spacing: .5px;
            }

            .btn-style {
                padding: 0 4px;

                &__label {
                    font-size: 12px;
                    letter-spacing: .5px;
                }

            }
        }
    }
}

.category-list,
.subcategory-list {
    @media #{$pre-mobile-plus} {

        position: absolute;
        border-radius: 5px;
        background-color: $grey_bg;
        box-sizing: border-box;

        &__item {
            position: static;
            padding: 0;

            &:before {
                content: none;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__link {
            display: block;
            cursor: pointer;

        }
    }

    @media #{$pre-mobile} {
        //border-bottom: 1px solid $persian_green;
        display: none;
        margin-top: 33px;

        &__link {
            text-transform: none;
            font-size: 12px;
            display: flex;

            &._have-inner {
                @include have-inner;
            }
        }
    }
}

.category-list {
    @media #{$pre-mobile-plus} {
        z-index: 25;
        left: 0;
        top: calc(100% + 10px);
        opacity: 0;
        pointer-events: none;
        transform: translateX(-50%);
        transition: transform 1s cubic-bezier(0.18, 1, 0.21, 1), opacity 0.7s cubic-bezier(0.18, 1, 0.21, 1);
        width: 100%;
        max-width: 306px;
        padding: 18px 23px;

        &__item {
            margin-bottom: 24px;
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: -14px;
            display: block;
            width: 100%;
            height: 15px;
        }
        &__link {
            position: relative;
            transition: .3s ease;
            padding-right: 22px;
            color: #848484;
            font-size: 16px;
            font-weight: 700;

            &:after {
                content: "\f105";
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(-10px);
                transition: .3s ease;
                opacity: 0;
                color: $persian_green;
                font-family: "FontAwesome";
                font-size: 23px;
                font-weight: 400;
            }

            &._have-inner._hover,
            &._have-inner:hover {
                color: $persian_green;

                &:after {
                    transform: translateX(0);
                    opacity: 1;
                }

                &~.subcategory-list {
                    transform: translateX(0);
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }

    @media #{$pre-mobile} {
        &__link {
            font-weight: 700;
            padding-bottom: 8px;
            margin-bottom: 16px;
        }
    }
}

.subcategory-list {
    @media #{$pre-mobile-plus} {
        z-index: 24;
        left: calc(100% + 10px);
        top: 0;
        opacity: 0;
        pointer-events: none;
        transform: translateX(-50%);
        transition: transform 1s cubic-bezier(0.18, 1, 0.21, 1), opacity 0.7s cubic-bezier(0.18, 1, 0.21, 1);
        padding: 20px 32px 22px 23px;
        &:before {
            content: "";
            position: absolute;
            left: -13px;
            top: 0;
            display: block;
            width: 16px;
            height: 100%;
        }
        &__item {
            margin-bottom: 9px;
        }

        &__link {
            transition: .3s ease;
            color: #7f7f7f;
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;

            &:hover {
                color: #231f20;
            }
        }

        &._hover,
        &:hover {
            transform: translateX(0);
            opacity: 1;
            pointer-events: auto;
        }
    }

    @media #{$pre-mobile} {
        &__link {
            font-weight: 400;
            color: $input-gray;
            margin-bottom: 23px;
        }
    }

}