
.map-wrap {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 500px;
}

.map {
    height: 100%;
    width: 100%;
}
