.feedback {
    background-color: $white;
    padding: 29px 0 96px;

    &__title {
        text-align: center;
    }

    @media #{$pre-mobile} {
        padding-bottom: 70px;
    }
}

.feedback-form {
    margin-top: 30px;
    padding-top: 12px;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__col {
        width: calc(50% - 10px);
        text-align: right;
    }

    .input-style {
        margin-top: 40px;

        &:first-child {
            margin-top: 0;
        }

        &__input {
            border-radius: 5px;
            height: 60px;

            &_comment {
                height: 185px;
            }
        }

        &__placeholder {
            font-size: 16px;
            left: 29px;
        }
    }

    .input-style__input_comment ~ .input-style__placeholder {
        top: 31px;
    }

    .input-style__input:focus ~ .input-style__placeholder,
    .input-style__input._active ~ .input-style__placeholder {
        top: 0;
    }

    .btn-style {
        height: 60px;
        width: 300px;
        margin-left: auto;
        margin-top: 10px;
    }

    &__annotation {
        width: 100%;
        max-width: 318px;
        margin-left: auto;
        margin-top: 15px;
        text-align: right;
        color: #000;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;

        a {
            transition: .3s ease;
            color: $persian_green;

            &:hover {
                color: $turquoise;
            }
        }
    }

    @media #{$tablet} {
        margin-top: 24px;

        .input-style {
            margin-top: 27px;

            &__input {
                height: 45px;

                &_comment {
                    height: 135px;
                }
            }
        }

        .btn-style {
            height: 44px;
            width: 220px;
            margin-top: 7px;
        }

        &__annotation {
            margin-top: 20px;
        }
    }

    @media #{$pre-mobile} {
        margin-top: 13px;

        &__wrapper {
            flex-wrap: wrap;
        }

        &__col {
            width: 100%;
        }
        &__col + &__col {
            margin-top: 24px;
        }

        &__annotation {
            margin: 20px auto 0;
            text-align: center;

            a {
                display: inline;
            }
        }

        .input-style {
            margin-top: 24px;

            &__input {
                height: 40px;

                &_comment {
                    height: 118px;
                }
            }
        }

        .btn-style {
            margin: 20px auto 0;
        }
    }
}