.calc-gallery {
    position: relative;
    padding: 52px 0 0;

    &:before {
        content: "";
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        background-color: rgba(0, 0, 0, 0.04);
        pointer-events: none;
        width: 100%;
        height: 100%;
    }

    &__slider-wrapper {
        position: relative;
    }

    &__dots-wrapper {
        width: 100%;
        max-width: 470px;
        margin: 0 auto;
    }

    @media #{$tablet} {
        padding: 30px 0 0;
    }
}

.title-product {
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 24px;

    &__art {
        margin-top: 13px;
        color: $grey_dark;
        font-size: 16px;
        font-weight: 300;
        text-transform: uppercase;
    }

    &__name {
        margin-left: 31px;
        margin-top: 5px;
        color: $black;
        font-size: 30px;
        font-weight: 400;
    }

    &__mod {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding: 16px 10px 4px;

        &:before {
            content: "";
            position: absolute;
            left: 2px;
            top: 0;
            display: block;
            transform: skewX(-37deg);
            width: 64px;
            height: 100%;
        }

        &_new:before {
            background-color: $blue;
        }

        &_used:before {
            background-color: $orange;
        }
    }

    &__mod-text {
        position: relative;
        color: $white;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
    }

    @media #{$pre-mobile} {
        flex-wrap: wrap;
        padding: 0;
        justify-content: flex-start;

        &__name {
            width: 100%;
            order: 1;
            margin: auto;
            text-align: center;
            font-size: 18px;
            margin-bottom: 18px;
        }

        &__mod {
            margin-left: 18px;
            padding: 4px 10px 4px;
            order: 3;

            &:before {
                left: 6px;
                top: 3px;
                width: 30px;
                height: 75%;
            }
        }

        &__mod-text {
            font-size: 8px;
        }

        &__art {
            margin-top: 0;
            font-size: 12px;
            order: 2;
        }
    }
}

.slick-arrow {
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 60px;
    padding: 0;

    &:before,
    &:after {
        content: "";
        position: absolute;
        left: 3px;
        top: 50%;
        transition: background-color .3s ease;
        transform: translateY(-50%);
        transform-origin: 0% 50%;
        background-color: $persian_green;
        display: block;
        width: 28px;
        height: 3px;
        padding: 0;
        font-size: 0;
        line-height: 0;
    }

    &:before {
        transform-origin: 0% 100%;
        transform: translateY(-50%) rotate(-45deg);
    }

    &:after {
        transform-origin: 0% 0%;
        transform: translateY(-50%) rotate(45deg);
    }

    &.slick-prev {
        right: auto;
        left: 0;
    }

    &.slick-next {
        right: 0;

        &:before,
        &:after {
            right: 3px;
        }

        &:before {
            transform-origin: 100% 0%;
        }

        &:after {
            transform-origin: 100% 100%;
        }
    }

    &:hover {
        &:before,
        &:after {
            background-color: $turquoise;
        }
    }

    @media #{$pre-mobile} {
        &:before,
        &:after {
            width: 20px;
            height: 2px;
            left: 50%;
        }


        &:before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(45deg);
        }

    }
}

.calc-slider {
    position: static;
    width: 100%;
    max-width: 630px;
    margin: 8px auto;

    &__item {
        position: relative;

        &:after {
            content: "";
            display: block;
            padding-top: 50%;
        }
    }

    &__img-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &__img {
        width: 100%;
        height: 100%;

        @include object-fit(contain, center);
    }

    @media #{$tablet} {
        margin: 17px auto;

        &__img-wrapper {
            width: 73%;
            margin: auto;
            left: 0;
            right: 0;
        }

        &__item {
            height: 212px;
        }
    }

    @media #{$pre-mobile} {
        &__item {
            height: 60px;
        }

        &__img-wrapper {
            box-sizing: border-box;
            padding: 0 5%;
        }
    }
}

.calc-dots {
    margin: 12px -10px 0;

    .slick-track {
        display: flex;
        justify-content: center;
        align-items: stretch;
        margin: 0 auto;
    }

    .slick-arrow {
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transform: none;
        height: 100%;
        width: 30px;

        &:after,
        &:before {
            left: 10px;
            width: 11px;
        }

        &.slick-disabled {
            opacity: 0;
            pointer-events: none;
        }

        &.slick-prev {
            left: -30px;
        }

        &.slick-next {
            right: -30px;
        }
    }

    &__item {
        position: relative;
        cursor: pointer;
        float: none;
        margin: 0 10px;

        &:before {
            z-index: 1;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            transition: opacity .3s ease;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.04);
        }

        &.slick-current {
            &:before {
                opacity: 0;
            }
        }

        &:after {
            content: "";
            display: block;
            padding-top: 62%;
        }
    }

    &__img-wrapper {
        z-index: 0;
        position: absolute;
        left: 10px;
        top: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
    }

    &__img {
        width: 100%;
        height: 100%;

        @include object-fit(contain, center);
    }
}