.banner {
	position: relative;
	height: auto;

	.nf {
		display: block;
		position: relative;
		width: 240px;
		height: 145px;
		margin: 0 auto 30px;
	}



	&_home {

	}

	&__bottom {
		display: block;
		@include absolute(auto,0,50px,0);
	}


	@media #{$tablet} {

	}
}

.banner-description {
	width: 100%;
	height: auto;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	position: relative;
	z-index: 5;
	transform: translateY(50px);
	opacity: 0;

	.subtitle {
		transform: translateY(25px);
	}

	.btn-style {
		opacity: 0;
	}

	&__label,
	&__search-text {
		color: $white;
	}

	&__label {
		font-size: 40px;
		font-weight: 400;
	}

	&__search-text {
		font-size: 80px;
		font-weight: 700;
	}

	.row {
		flex-wrap: nowrap;
	}

	.row:last-child {
		margin-bottom: 0;
	}

	.container {

	}

	.title,
	.subtitle {
		width: 100%;
	}

	.title {
		text-transform: uppercase;
		text-align: left;
	}

	.row {
		margin-top: 20px;
	}

	.subtitle {
		text-align: left;
		margin-top: 8px;
	}
}

.banner-slider {
	display: block;
	width: 100%;
	height: auto;
	position: relative;

	.slick-dots {
		li {
			button {
				padding: 8px;

				&::before {
					content: '';
					@include absolute;
					width: 6px;
					height: 6px;
					background: $black;
					border-radius: 50%;
					z-index: 2;
					//opacity: 0;
					transform: scale(0);
					transition: 0.3s ease-in-out;
				}

				&::after {
					content: '';
					@include absolute;
					width: 6px;
					height: 6px;
					background: $white;
					border-radius: 50%;
					z-index: 1;
				}
			}

			&.slick-active {
				button {
					&::before {
						opacity: 1;
						transform: scale(1.7);
					}
				}
			}
		}
	}

	.bg {
		position: absolute;
		height: 100%;
		width: 100%;
		display: flex;
		transform: skewX(-37deg);
		align-items: flex-end;
		left: -80px;

		&__line {
			position: absolute;
			bottom: 0;
			display: block;
			height: 100%;

			&_left {
				width: 50%;
				transform: translateY(-100%);
				left: 0;
				right: auto;
				z-index: 1;
				top: 0;
			}

			&_mid {
				width: 60%;
				transform: translateY(100%);
				left: 13%;
				right: auto;
				z-index: 2;
				top: 0;
				transition-delay: .2s;
			}

			&_right {
				width: 13%;
				height: 40%;
				background: $white;
				transform: translateY(100%);
				right: 17%;
				left: auto;
				z-index: 3;
				top: auto;
				transition-delay: .3s;
			}
		}
	}

	&__list {
		display: block;
		position: relative;
		width: 100%;
		height: auto;
	}

	&__list:not(.slick-initialized) &__item:not(:first-child) {
		display: none;
	}

	&__item {
		display: block;
		position: relative;
		width: 100%;
		height: 530px;
		background: $grey_horse;
		overflow: hidden;

		img {
			transition: transform 0s ease, opacity .5s ease;
			position: relative;
			transform: translateX(100vw);
			opacity: 1;
		}

		&.slick-active {
			img {
				transition: transform 1s ease, opacity 0s ease;
				transform: translateX(0);
				opacity: 1;
			}
		}
	}

	&__item.slick-active,
	&__item._active {
		.bg {
			&__line {
				transition: all 1s linear;
				transition-delay: .5s;
				transform: translateY(0);
			}
		}
		.banner-description {
			transition: all .5s linear;
			transition-delay: 1s;
			transform: translateY(0);
			opacity: 1;
		}

		.subtitle {
			transition: all .5s linear;
			transition-delay: 1s;
			transform: translateY(0);
		}

		.btn-style {
			transition: all .3s linear;
			transition-delay: 1.5s;
			opacity: 1;
		}
	}

	&__table {
		display: flex;
		width: 100%;
		height: 100%;
		position: relative;
	}

	&__col {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;

		&_left {
		}

		&_right {
		}
	}


	.slick-dots {
		max-width: 960px;
		margin: auto;
		left: 0;
		right: 0;
		text-align: left;
		bottom: 35px;
	}

	@media #{$tablet} {
		&__table {
			flex-direction: column;
			align-items: center;
		}

		&__item {
			height: 822px;
		}

		&__col {
			width: 100%;
			height: 50%;
			justify-content: center;

			&_right {
				align-items: flex-start;
				height: calc(50% - 85px);
			}
		}

		&__item img {
			max-width: 100%;
			max-height: 100%;
		}

		.banner-description {
			align-items: center;

			.title,
			.subtitle {
				text-align: center;
			}
		}

		.slick-dots {
			text-align: center;
			bottom: 47px;
			padding-bottom: 0;
		}


		.bg {
			left: -98px;
			transform: skewX(-29deg);

			&__line {
				&_left {
					width: 60%;
				}

				&_mid {
					width: 84%;
					left: 21%;
				}

				&_right {
					width: 17%;
					right: -15%;
				}
			}
		}

	}

	@media #{$pre-mobile} {
		&__item {
			height: 450px;
		}

		.bg {
			left: -34px;
		}

		.slick-dots {
			bottom: 10px;

			li {
				button {
					padding: 4px;
				}
			}
		}

		&__col_right {
			height: calc(50% - 35px);
		}
	}

}