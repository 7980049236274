.tariff {
	position: relative;
	display: flex;
	margin: 50px 0;
	align-items: stretch;

	.separate {
		display: block;
		position: relative;
		height: 3px;
		width: 100%;
		margin: 14px 0 10px;
	}

	&__item {
		width: calc(100% / 3 - 40px / 3);
		display: flex;
		align-items: flex-start;
		justify-content: center;
		position: relative;
		z-index: 7;


		&:not(._active) {
			&::before {
				content: '';
				display: block;
				@include absolute;
				width: 100%;
				height: calc(100% - 60px);
				background: $silver;
				z-index: 1;
			}
		}

		&._active  {
			width: calc(100% / 3 + 40px / 3 * 2);
			border: 2px solid $grey_horse;
			background: $white;
			box-sizing: content-box;
			z-index: 9;
			transform: scale(.9);
			opacity: 0;
		}

		&:first-child {
			transform: translateX(100%);
			opacity: 0;
		}

		&:last-child {
			transform: translateX(-100%);
			opacity: 0;
		}
	}

	&._animate & {
		&__item {
			transition: all 1s ease;
			opacity: 1;

			&._active  {
				transform: scale(1);
				transition-delay: .5s;
			}

			&:first-child {
				transform: translateX(0);
				transition-delay: 1s;
			}

			&:last-child {
				transform: translateX(0);
				transition-delay: 1s;
			}
		}
	}

	&__content {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		max-width: 220px;
		z-index: 2;
		text-align: center;
		padding: 70px 0 90px;

		& > * {
			width: 100%;
		}

		h3 {
			width: 150px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			margin: 0;
		}

		h5 {
			margin-bottom: 0;
			margin-top: 5px;
		}

	}

	@media #{$tablet} {
		margin: 32px 0;

		.separate {
			margin: 10px 0;
			max-width: 188px;
		}
		&__item:not(._active)::before {
			height: calc(100% - 44px);
		}


		&__content {
			padding: 47px 0;

			h3 {
				font-size: 24px;
			}

			h5 {
				font-size: 14px;
			}

			p {
				font-size: 14px;
				padding: 0 8px;
				box-sizing: border-box;
				line-height: 1.45;
			}
		}
	}

	@media #{$pre-mobile} {
		flex-direction: column;

		&__item {
			margin: auto;
			width: calc(100% - 100px);

			&._active {
				width: calc(100% - 60px);
			}

			&::before {
				height: 100% !important;
			}


			&:first-child {
				transform: translateY(100%);
				opacity: 0;
			}

			&:last-child {
				transform: translateY(-100%);
				opacity: 0;
			}
		}

		&._animate & {
			&__item {
				transition: all 1s ease;
				opacity: 1;

				&:first-child {
					transform: translateY(0);
					transition-delay: 1s;
				}

				&:last-child {
					transform: translateY(0);
					transition-delay: 1s;
				}
			}
		}

		.separate {
			margin: 8px 0;
		}

		&__content {
			padding: 20px 25px;

			h3 {
				font-size: 18px;
				line-height: 1.3;
				display: flex;
				justify-content: center;
				align-items: center;
				max-width: 120px;
			}

			h5 {
				font-size: 12px;
			}

			p {
				font-size: 12px;
			}
		}

		&__item._active &__content {
			padding: 35px 25px;
		}
	}
}