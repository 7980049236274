.product-cart {
    background-color: rgba(0, 0, 0, 0.04);
    padding: 52px 0 0;

    @media #{$pre-mobile} {
        padding: 20px 0 0;
    }
}

.cart-unit {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 48px;

    &__col {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-direction: column;

        &_left {
            width: 65%;
        }

        &_right {
            width: 32%;
        }
    }

    &__dots-wrapper {
        width: 100%;
        max-width: 470px;
        margin: 0 auto;
    }

    .calc-slider {
        position: relative;
        width: 100%;
        margin: 0;

        &__item {
            background-color: $white;
            box-sizing: border-box;

            &:before {
                content: "";
                z-index: 1;
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                background-color: rgba(0, 0, 0, 0.04);
                width: 100%;
                height: 100%;
            }

            &:after {
                padding-top: 57%;
            }
        }

        &__img-wrapper {
            box-sizing: border-box;
            padding: 0 5%;
        }
    }

    .calc-dots {
        &__item {
            background-color: $white;
        }

        &__img-wrapper {
            top: 0;
            left: 0;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 0 10%;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                background-color: rgba(0, 0, 0, 0.04);
                width: 100%;
                height: 100%;
            }
        }
    }


    @media #{$pre-mobile-plus} {
        .calc-slider {
            .slick-arrow {
                display: none !important;
            }
        }
    }

    @media #{$tablet} {
        flex-wrap: wrap;

        &__col {
            width: 100%;
        }

        .calc-slider {
            margin: auto;
            max-width: 500px;

            &__item {
                height: 220px;
            }
        }
    }

    @media #{$pre-mobile} {
        margin-top: 0;

        &__dots-wrapper {
            display: none;
        }

        .calc-slider {
            &__item {
                height: 110px;
            }
        }
    }

}

.description {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1 0 auto;
    padding-bottom: 32px;

    &__calc-wrap {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: column;
        padding: 30px 0;
    }

    &__guarantee {
        margin-top: 6px;
    }

    &__guarantee-title {
        margin-bottom: 5px;
        margin-left: 2px;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo-wrapper {
        width: 92px;
        height: 37px;
    }

    &__img-logo {
        width: 100%;
        height: 100%;
        filter: grayscale(1);

        @include object-fit(contain, center);
    }

    .availability {
        width: 142px;
    }

    @media #{$tablet} {
        padding-bottom: 0;
        margin-top: 75px;

        &__calc-wrap {
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: flex-end;
        }

        &__top {
            max-width: 285px;
            margin: auto;
            width: 100%;
        }

        &__guarantee {
            margin-top: 25px;

            h5 {
                font-size: 16px;
            }
        }
    }

    @media #{$pre-mobile} {
        margin-top: 20px;

        .availability {
            width: 113px;
            text-align: end;
        }

        &__calc-wrap {
            padding: 15px 0 30px;
        }

        &__top {
            justify-content: center;
        }

        &__logo-wrapper {
            width: 55px;
            height: 25px;
        }

        &__guarantee {
            margin-top: 30px;
        }

        &__guarantee h5 {
            font-size: 12px;
        }
    }
}

.price-block {
    &__total {
        color: $black;
        font-size: 24px;
        font-weight: 400;
        line-height: 1;

        &_fiz {
            font-size: 48px;
        }
    }

    &__title {
        display: block;
        margin: 7px auto 3px 2px;
        color: $black;
        font-size: 14px;
        font-weight: 300;
    }

    @media #{$tablet} {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        &__total {
            padding-right: 20px;
            box-sizing: border-box;
        }

        &__title {
            margin-top: 0;
        }
    }
    @media #{$pre-mobile} {
        width: calc(50% + 15px);
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &__total {
            font-size: 18px;
            padding-right: 0;

            &_fiz {
                font-size: 22px;
            }
        }

        &__title {
            font-size: 12px;
            margin-bottom: 5px;
            margin-top: 10px;
        }
    }
}

.in-basket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 14px;
    padding-right: 18px;
    margin-top: 25px;

    .btn-style {
        width: 170px;
    }

    @media #{$tablet} {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        box-sizing: border-box;
        justify-content: flex-start;
        margin-top: 15px;
    }
    @media #{$pre-mobile} {
        width: calc(50% - 25px);
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .btn-style {
            width: 100%;
        }
    }
}

.counter-cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    &__icon {
        cursor: pointer;
        color: $persian_green;
        font-family: "FontAwesome";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    &__input {
        background-color: transparent;
        width: 48px;
        color: $black;
        font-family: "Roboto Slab";
        font-size: 24px;
        font-weight: 400;
        line-height: 1;
        text-align: center;
    }

    @media #{$tablet} {
        margin-bottom: 0;
        padding-right: 20px;
        box-sizing: border-box;
    }

    @media #{$pre-mobile} {
        padding-right: 0;
        margin-bottom: 10px;
    }
}