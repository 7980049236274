.footer {
    background-color: $black;

    &__top {
        padding: 30px 0 24px;
        border-bottom: 3px solid $black_light;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__middle {
        padding: 21px 0 15px;
    }

    &__bottom {
        padding: 38px 0 61px;
        background-color: $black_light;
    }

    &__description {
        color: $grey_med;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__col {
        width: calc(100% / 4);
    }

    &__address,
    &__schedule {
        color: $white;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
    }

    &__address {
        margin-top: 3px;
    }

    @media #{$tablet} {
        &__col {
            width: calc(100% / 4 - 40px / 3);
        }

        &__top {
            padding: 14px 0;
        }

        &__bottom {
            padding-top: 30px;
        }

        &__address,
        &__schedule {
            font-size: 12px;
        }

        &__address {
            margin-top: 0;
        }
    }
    @media #{$pre-mobile} {
        &__row {
            flex-wrap: wrap;
            align-items: stretch;
            max-width: 300px;
            margin: auto;
        }

        &__col {
            &_privacy {
                width: 100%;
                order: 3;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-top: 25px;
            }

            &_address,
            &_links {
                width: calc(50% - 10px);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

            &_address {
                order: 1;
            }
            &_links {
                order: 2;
            }
            &_tutmee {
                width: 100%;
                order: 4;
                margin-top: 40px;
            }
        }

        &__address,
        &__schedule {
            line-height: 1.5;
            font-weight: 300;
        }

        &__middle {
            padding: 35px 0;
        }

        &__bottom {
            padding-top: 20px;
        }
    }
}

.logo-footer {
    display: block;
    width: 200px;
    height: 37px;

    &__img {
        display: block;
        width: 100%;
        height: 100%;
    }

    @media #{$tablet} {
        width: 105px;
        height: 20px;
    }
}

.tutmee {
    margin-top: 3px;
    display: block;
    text-align: right;
    border: none;

    &__text,
    &__svg {
        display: inline-block;
        vertical-align: middle;
    }

    &__text {
        transition: 0.3s ease;
        margin-right: 3px;
        padding-top: 0px;
        color: #818181;
        font-family: Arial;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        text-align: right;
    }

    &__svg {
        width: 36px;
        height: 53px;
    }

    &__img {
        display: block;
        width: 100%;
        height: 100%;
    }

    &:hover {
        border: none;
    }

    &:hover & {
        &__text {
            color: $white;
        }
    }

    @media #{$pre-mobile} {
        text-align: center;
    }
}

.privacy {
    transition: .3s ease;
    margin-top: 3px;
    color: $turquoise;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.7px;

    &:hover {
        color: $white;
    }

    @media #{$tablet} {
        font-size: 12px;
        margin-top: 0;
        line-height: 1.2;
    }

    @media #{$pre-mobile} {
        width: calc(50% - 10px);
    }
}

.copyright {
    margin-top: 29px;
    color: $grey_dark;
    font-size: 14px;
    font-weight: 300;

    @media #{$tablet} {
        font-size: 12px;
    }

    @media #{$pre-mobile} {
        width: calc(50% - 10px);
        text-align: right;
        margin-top: 0;
        font-size: 14px;
    }
}

.footer-links {
    &__link {
        display: flex;
        width: 100%;

        &_phone {
            font-size: 24px;
            font-weight: 400;
            line-height: 1;
        }

        &_icq {
            margin-top: 7px;
            font-size: 14px;
            font-weight: 300;

            &:before {
                content: "\e906";
                display: inline-block;
                vertical-align: middle;
                margin-right: 9px;
                font-family: 'icomoon';
                color: inherit;
                font-size: 17px;
            }
        }

        &_phone,
        &_icq {
            color: $white;
            transition: .3s ease;

            &:hover {
                color: $turquoise;
            }
        }

        &_mail {
            transition: .3s ease;
            margin-top: 2px;
            color: $turquoise;
            font-size: 14px;
            font-weight: 300;

            &:hover {
                color: $white;
            }
        }
    }

    @media #{$tablet} {
        &__link {
            &_phone {
                font-size: 18px;
            }
        }
    }
    @media #{$pre-mobile} {

        &__link {
            &_phone {
                font-size: 16px;
                font-weight: 400;
            }

            &_icq {
                font-size: 16px;
                font-weight: 300;
            }
        }
    }
}