.default-slider {
    overflow: hidden;
    width: 100%;
    position: relative;

    &__list {
        position: relative;
        list-style-type: none;
        padding: 0;
        text-align: center;
        margin: auto;
        width: 1000px;
        height: 200px;
    }

    &__item {
        display: inline-block;
        width: 245px;
        margin: 0 40px;
        position: relative;
        outline: none;
        cursor: pointer;
    }

    &__img-wrap {
        margin: auto;
        width: 100px;
        height: 100px;
        background: green;
    }

    &__img {
        position: relative;
    }

    &__text {
        margin: 20px auto;
    }

    .slick-arrow {
        height: 65px;
        width: 65px;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;

        &::before {
            font-family: FontAwesome, sans-serif;
            font-size: 50px;
            color: green;
        }

        &:hover {
            &::before {
                color: $black;
            }
        }
    }

    .slick-prev,
    .slick-next {
        &:not(.slick-arrow) {
            display: none;
        }
    }

    .slick-prev {
        left: 0;
        margin-left: 0;

        &::before {
            content: '\f104';
            float: left;
        }
    }

    .slick-next {
        right: 0;
        margin-right: 0;

        &::before {
            content: '\f105';
            float: right;
        }
    }
}