@font-face {
    font-family: "Roboto Slab";
    src: url("../fonts/robotoslab-light-webfont.woff2") format("woff2"), url("../fonts/robotoslab-light-webfont.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto Slab";
    src: url("../fonts/robotoslab-regular-webfont.woff2") format("woff2"), url("../fonts/robotoslab-regular-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto Slab";
    src: url("../fonts/robotoslab-bold-webfont.woff2") format("woff2"), url("../fonts/robotoslab-bold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

$rb: "Roboto Slab";

@font-face {
    font-family: 'icomoon';
    src: url("../fonts/icomoon.eot?drebi7");
    src: url("../fonts/icomoon.eot?drebi7#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?drebi7") format("truetype"), url("../fonts/icomoon.woff?drebi7") format("woff"), url("fonts/icomoon.svg?drebi7#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-courier:before {
    content: "\e900";
}

.icon-credit-card:before {
    content: "\e901";
}

.icon-delivery-man:before {
    content: "\e902";
}

.icon-filter-results-button:before {
    content: "\e903";
}

.icon-free-delivery:before {
    content: "\e904";
}

.icon-IBM_logo:before {
    content: "\e905";
}

.icon-icq:before {
    content: "\e906";
}

.icon-list:before {
    content: "\e907";
}

.icon-medal:before {
    content: "\e908";
}

.icon-pdf:before {
    content: "\e909";
}

.icon-placeholder:before {
    content: "\e90a";
}

.icon-placeholder-2:before {
    content: "\e90b";
}

.icon-price-tag:before {
    content: "\e90c";
}

.icon-shipped:before {
    content: "\e90d";
}

.icon-shopping-cart:before {
    content: "\e90e";
}

.icon-stock:before {
    content: "\e90f";
}

.icon-telephone:before {
    content: "\e910";
}

.icon-clock:before {
    content: "\e912";
}

.icon-contact:before {
    content: "\e913";
}