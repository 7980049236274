.p404 {
	min-height: 100vh;
	background: $lemon_cream;

	&__description {
		position: relative;

		h1 {
			position: relative;
			font-size: 566px;
			z-index: 1;
			color: $white;
			animation: opacity-toggle 5s ease infinite;
			animation-delay: 1s;
		}

		p {
			@include absolute;
			font-size: 30px;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
		}
	}

	@keyframes opacity-toggle {
		0% {
			opacity: 1;
		}
		30% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
		80% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}

	@media #{$pre-tablet} {
		&__description {
			h1 {
				font-size: 500px;
			}
		}
	}

	@media #{$tablet} {
		.container {
			padding-top: 110px;
		}

		&__description {
			h1 {
				font-size: 400px;
			}
			p {
				font-size: 20px;
			}
		}
	}
	@media #{$pre-mobile} {
		.container {
			padding-top: 100px;
		}

		&__description {
			text-align: center;

			h1 {
				font-size: 160px;
			}
			p {
				font-size: 12px;
			}
		}
	}

}