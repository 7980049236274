.delivery {
    background-color: $white;
    padding: 39px 0 122px;

    &__subtitle {
        margin-top: 37px;
    }

    &__list-title,
    &__list-item {
        color: $black;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.1px;
    }

    &__list-item {
        &:before {
            content: "\f00c";
            margin-right: 14px;
            color: $black;
            font-family: $fa;
            font-size: 16px;
            font-weight: 400;
        }
    }

    &__list-wrapper {
        margin-top: 43px;
        margin-bottom: 42px;
    }

    @media #{$tablet} {
        padding: 33px 0 90px;

        &__subtitle {
            margin-top: 27px;
        }

        &__list-title,
        &__list-item {
            font-size: 14px;
        }

        &__list-item {
            &:before {
            }
        }

        &__list-wrapper {
            margin-top: 38px;
            margin-bottom: 59px;
        }
    }

    @media #{$pre-mobile} {
        padding-bottom: 70px;

        &__list-wrapper {
            margin-top: 20px;
            margin-bottom: 30px;
        }

        &__list-title,
        &__list-item {
            font-size: 12px;
            line-height: 1.5;
        }

        &__delivery-distance-list {
            margin-top: 15px;
        }
        &__list-item {
            font-size: 12px;
        }
    }
}

.delivery-type {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 48px;

    &__col {
        position: relative;
        box-sizing: border-box;
        width: calc(50% - 10px);
        padding-left: 93px;
    }

    &__icon {
        position: absolute;
        left: 0;
        top: 4px;
        font-size: 66px;
    }

    &__title,
    &__item {
        color: $black;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.1px;
    }

    &__item {
        &:before {
            content: "-";
            margin-right: 4px;
        }
    }

    & ~ .attention {
        margin-top: 50px;
    }

    @media #{$tablet-only} {
        &__col {
            padding-left: 0;
            padding-top: 85px;
        }
    }

    @media #{$tablet} {
        margin-top: 26px;

        &__title,
        &__item {
            font-size: 14px;
        }
    }
    @media #{$pre-mobile} {
        flex-wrap: wrap;

        &__col {
            width: 100%;
            padding-left: 75px;
            margin-bottom: 35px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__icon {
            font-size: 55px;
        }

        &__title,
        &__title {
            font-size: 12px;
            line-height: 1.5;
        }


    }
}