.ready-solutions {
    background-color: $white;
    padding: 80px 0 93px;

    &_product-page {
        padding-top: 25px;
    }

    .title {
        position: relative;
    }

    &__all-products {
        position: absolute;
        right: 0;
        bottom: 7px;
        transition: .3s ease;
        color: $persian_green;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.8px;

        &:after {
            content: "\f061";
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 2px;
            margin-left: 6px;
            font-family: "FontAwesome";
            font-size: 12px;
            font-weight: 400;
        }

        &:hover {
            color: $turquoise;
        }
    }

    .catalog-list {
        margin-top: 49px;

        .slick-arrow {
            &.slick-prev {
                left: -45px;
            }

            &.slick-next {
                right: -45px;
            }
        }
    }

    .attention {
        margin-top: 35px;
    }

    @media #{$tablet-only} {
        .catalog-list {
            .slick-arrow {
                top: 100%;
                transform: translateY(20px);
                margin: auto;

                &:before,
                &:after {
                    width: 22px;
                    height: 2px;
                }

                &.slick-prev {
                    left: -80px;
                    right: 0;
                }

                &.slick-next {
                    right: -80px;
                    left: 0;
                }
            }

            & + .attention {
                margin-top: 100px;
            }
        }
    }

    @media #{$tablet} {
        padding: 63px 0 93px;

        .catalog-list {
            margin-top: 49px;
        }

        &__all-products {
            font-size: 14px;
        }
    }

    @media #{$pre-mobile} {
        padding-top: 22px;
        padding-bottom: 70px;

        .title {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            font-size: 20px;
        }

        &__all-products {
            font-size: 12px;
            position: relative;
            margin-top: 28px;
        }

        .catalog-list {
            margin-top: 18px;

            &_slider {
                width: calc(100% - 80px);
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}