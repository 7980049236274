$pre-tablet: "only screen and (max-width: 1150px)";
$tablet: "only screen and (max-width: 1000px)";
$tablet-plus: "only screen and (min-width: 1001px)";
$tablet-only: "only screen and (min-width: 751px) and (max-width: 1000px)";
$pre-mobile: "only screen and (max-width: 750px)";
$pre-mobile-plus: "only screen and (min-width: 751px)";
$mobile: "only screen and (max-width: 700px)";
$mobile-plus: "only screen and (min-width: 701px)";
$mobile-small: "only screen and (max-width: 500px)";

.full-on-pre-mob {
	@media #{$pre-mobile} {
		width: calc(100% + 20px);
		left: -10px;
	}
}

@mixin full-on-pre-mob {
	@media #{$pre-mobile} {
		width: calc(100% + 20px);
		left: -10px;
	}
}

.hide-on-pre-mobile {
	@media #{$pre-mobile} {
		display: none !important;
	}
}

.show-on-pre-mobile {
	@media #{$pre-mobile-plus} {
		display: none !important;
	}
}

.hide-on-mobile {
	@media #{$mobile} {
		display: none !important;
	}
}

@mixin absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
	position: absolute;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
	margin: auto;
}

@mixin have-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid transparent;
	transition: all .3s ease;

	&::after {
		content: '\f106';
		font-family: $fa;
		transition: transform .3s ease;
		position: relative;
		display: block;
		color: $persian_green;
		font-size: 14px;
	}

	&._active {
		color: $persian_green;
		border-color: $persian_green;

		&::after {
			transform: rotateX(180deg);
		}
	}
}

@mixin btn-styles {
	$btn: &;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
	border-radius: 5px;
	border: 3px solid $persian_green;
	box-sizing: border-box;
	background-color: transparent;
	cursor: pointer;
	height: 45px;

	&__label {
		z-index: 2;
		position: relative;
		transition: .3s ease;
		color: $black;
		font-size: 16px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 0.8px;
	}

	&:before,
	&:after {
		content: "";
		position: absolute;
		display: block;
		transition: .3s ease;
		background-color: $persian_green;
		width: 60%;
		height: 100%;
	}

	&:before {
		top: 0;
		left: -9%;
		transform: translate(-10%, -100%) skewX(-30deg);
	}

	&:after {
		top: 0;
		right: -9%;
		transform: translate(-10%, 100%) skewX(-30deg);
	}

	&:hover {
		&:before,
		&:after {
			transform: translate(0, 0) skewX(-30deg);
		}

		#{$btn}__label {
			color: $white;
		}
	}

	&_grey {
		border-color: $grey_horse;

		&:before,
		&:after {
			background-color: $grey_horse;
		}

		#{$btn}__label {
			color: $persian_green;
		}

		&:hover {
			#{$btn}__label {
				color: $black;
			}
		}
	}

	&_grey-dark {
		border-color: $grey_med;

		&:before,
		&:after {
			background-color: $grey_med;
		}

		#{$btn}__label {
			color: $persian_green;
		}

		&:hover {
			#{$btn}__label {
				color: $black;
			}
		}
	}


	//color
	&_white-label {
		#{$btn}__label {
			color: $white;
		}

		&:hover {
			#{$btn}__label {
				color: $black;
			}
		}
	}
	//color
	&_black-label {
		#{$btn}__label {
			color: $black;
		}

		&:hover {
			#{$btn}__label {
				color: $persian_green;
			}
		}
	}

	//size

	&_white-border {
		border-color: $white;
	}

	&_middle {
		height: 50px;
	}

	&_small {
		height: 35px;

		#{$btn}__label {
			font-size: 14px;
			letter-spacing: .7;
		}
	}

	&_min220 {
		min-width: 220px;
	}

	&_text-space & {
		&__label {
			padding: 0 10px;
		}
	}

	@media #{$tablet} {
		&_min220 {
			min-width: 185px;
		}

		&__label {
			font-size: 14px;
		}

		&_middle {
			height: 43px;
		}
	}
	@media #{$pre-mobile} {
		height: 33px;

		&__label {
			font-size: 12px;
		}
	}
}