@import "mixins";
@import "../plugins/mixin-object-fit";

$default_transition: 0.5s ease-in;

body {
    display: flex;
    height: 100vh;
    flex-direction: column;
    font-family: $rb;
    color: $black;
    line-height: normal;
}

footer {
    flex-shrink: 0;
    min-height: 100px;
    background: #3c3c3c;
}

h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 2.2px;

    @media #{$tablet} {
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 0;
    }
    @media #{$pre-mobile} {
        font-size: 24px;
    }
}

h2 {
    font-size: 48px;
    font-weight: 400;
    line-height: 1.2;

    @media #{$tablet} {
        font-size: 40px;
    }
    @media #{$pre-mobile} {
        font-size: 24px;
    }
}

h3 {
    font-size: 30px;
    font-weight: 400;
    line-height: 1.2;

    @media #{$pre-mobile} {
        font-size: 20px;
    }
}

h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;

    @media #{$tablet} {
        font-size: 16px;
    }
    @media #{$tablet} {
        font-size: 14px;
        line-height: 1.3;
    }
}

h5 {
    font-size: 16px;
    font-weight: 700;

    @media #{$pre-mobile} {
        font-size: 14px;
        line-height: 1.3;
    }
}

.content {
    flex: 1 0 auto;
    display: block;
    width: 100%;
    overflow: hidden;

    @media #{$pre-mobile} {
        padding-top: 48px;
    }
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 1028px;
    padding: 0 34px;
    box-sizing: border-box;

    &._wide {
        max-width: 1468px;
    }

    @media #{$pre-mobile} {
        padding: 0 10px;
    }
}

.block-wrapper {
    position: relative;
    display: block;
    margin-bottom: 100px;

    @media #{$tablet} {
        margin-bottom: 58px;
    }

    @media #{$mobile} {
        margin-bottom: 35px;
    }
}

ul.check-ul,
.check-ul ul {
    margin-top: 20px;

    li {
        font-weight: 300;
        font-size: 16px;
        padding-left: 24px;

        &:before {
            content: "\f00c";
            margin-right: 14px;
            color: $black;
            font-family: $fa;
            font-size: 16px;
            font-weight: 400;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    @media #{$tablet} {
        li {
            font-size: 14px;

            &:before {
                margin-right: 8px;
                font-size: 14px;
                line-height: 1.75;
            }
        }
    }
    @media #{$pre-mobile} {
        li {

            font-size: 12px;
            line-height: 1.5;

            &:before {
                font-size: 16px;
                line-height: 1;
            }
        }
    }
}
ul.mini-ul,
.mini-ul ul {
    li {
        font-weight: 300;
        font-size: 16px;

        &:before {
            content: "-";
            margin-right: 4px;
        }
    }

    @media #{$tablet} {
        li {
            font-size: 14px;
            line-height: 1.75;
        }
    }
    @media #{$pre-mobile} {
        li {

            font-size: 12px;
            line-height: 1.5;
        }
    }
}

.content-block {
    padding-top: 50px;
    padding-bottom: 130px;

    p {
        margin-bottom: 50px;
        font-weight: 300;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: .1px;
    }

    h3 {
        margin-bottom: 50px;
    }

    ul {
        margin-bottom: 50px;
    }

    &._big-ul {
        ol {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            counter-reset: list;
            margin-bottom: 15px;

            li {
                width: calc(50% - 10px);
                padding-right: 30px;
                box-sizing: border-box;
                margin-bottom: 40px;
                padding-left: 100px;
                font-size: 14px;
                line-height: 1.75;
                font-weight: 300;

                &::before {
                    content: counter(list);
                    counter-increment: list;

                    @include absolute(0, auto, auto);

                    font-family: $rb;
                    font-size: 150px;
                    font-weight: 600;
                    line-height: .8;
                    color: $grey_horse;
                }
            }
        }
    }

    &_min {
		line-height: 1.5;

        h2 {
            margin-bottom: 50px;
        }

        p {
            margin-bottom: 0;
        }

        h3 {
            margin-bottom: 0;
        }

        ul {
            margin-bottom: 0;
        }

        .attention {
			margin-bottom: 40px;
			margin-top: 50px;
        }

        h5 {
            margin-bottom: 15px;
        }

        ul {
            & + h3 {
                margin-top: 50px;
            }

            & + h5 {
                margin-top: 30px;
            }

            & + p {
                margin-top: 20px;
            }
        }
    }

    .note {
        position: relative;
        font-size: 14px;
        padding-left: 20px;
        box-sizing: border-box;
		margin-top: 23px;
		margin-bottom: 27px;
        color: $gray;
		line-height: 1.3;

        &::before {
            content: '*';
            display: block;
            @include absolute(0,auto,auto);
            font-family: inherit;
            font-size: inherit;
            color: inherit;
        }
    }

    @media #{$tablet} {
        padding-top: 33px;
        padding-bottom: 80px;

        &._big-ul {
            ol {
                margin-bottom: 0;

                li {
                    width: 100%;
                    margin-bottom: 52px;

                    &::before {
                        font-size: 130px;
                    }
                }
            }
        }

        p {
            font-size: 14px;
            line-height: 1.75;
        }

        &_min {
            .attention {
                margin-bottom: 30px;
                margin-top: 40px;
            }

            h2 {
                margin-bottom: 28px;
            }
            ul {
                & + h3 {
                    margin-top: 40px;
                }

                & + h5 {
                    margin-top: 28px;
                }

                & + p {
                    margin-top: 20px;
                }
            }
        }

        .note {
            font-size: 12px;
        }
    }

    @media #{$pre-mobile} {
        padding-top: 27px;
        padding-bottom: 65px;

        p {
            margin-bottom: 45px;
            font-size: 12px;
            line-height: 1.5;
        }

        h3 {
            margin-bottom: 35px;
        }

        &._big-ul {
            ol {
                li {
                    padding-right: 0;
                    padding-left: 66px;
                    margin-bottom: 35px;
                    font-size: 12px;
                    line-height: 1.5;

                    &::before {
                        font-size: 100px;
                    }
                }
            }
        }

        &_min {
            h5 {
                margin-bottom: 12px;
            }

            ul+h5 {
                margin-top: 36px;
            }
        }

        .note {
            font-size: 11px;
        }

    }
}

.row {
    margin-bottom: 30px;

    &_around {
        display: flex;
        justify-content: space-around;
    }
}

.clear {
    display: block;
    clear: both;
}

.hide {
    display: none;
}

.title {
    text-align: center;

    &._space {
        margin: 50px 0;

        @media #{$tablet} {
            margin: 35px 0;
        }
        @media #{$pre-mobile} {
            margin: 35px 0 20px;
        }
    }
}

.center_img {
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        display: block;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
    }
}

a {
    display: inline-block;
    text-decoration: none;
    transition: $default_transition;
    font-size: inherit;
    color: inherit;
    position: relative;

    &.underline {
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 0;
            height: 1px;
            top: auto;
            bottom: 0;
            left: 0;
            transition: .5s ease;
            background: black;
        }

        &:hover {
            transition: $default_transition;
            color: $black;

            &::before {
                width: 100%;
            }
        }
    }
}

strong {
    font-weight: 700;
}

em {
    font-style: italic;
}

li {
    position: relative;
    font-size: 18px;
    font-weight: 400;
}

p {
    font-size: 18px;
    font-weight: 400;

    &:last-child {
        margin-bottom: 0;
    }
}

.row {
    display: flex;
    align-items: center;
    justify-content: center;
}

label {
    font-family: $rb;
    font-size: 18px;
    font-weight: 600;
    margin-right: 25px;

    @media #{$tablet} {
        font-size: 16px;
    }
}

.btn-style {
    @include btn-styles;
}

.nav-btn {
    @media #{$pre-mobile-plus} {
        @include btn-styles;
    }
}

/****checkbox radio style*****/

.checkbox {
    //padding-left: 36px;
    box-sizing: border-box;
    position: relative;
    //min-height: 24px;

    &__wrap {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__label {
        text-align: left;
        cursor: pointer;
        display: block;
        font-size: 14px;
        font-family: $rb;
        font-weight: 400;
        color: $input-gray;


        a {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            color: inherit;

            &::before,
            &::after  {
                background: $white;
            }

            &:hover {
                color: $white;

                &::before,
                &::after {
                    background: $white;
                }
            }
        }
    }

    &__input {
        height: 24px;
        width: 24px;
        background: $grey_white;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        margin-right: 12px;

        &.error {
            box-sizing: border-box;
            border: 1px solid red;
        }

        &::before {
            content: '\f00c';
            font-family: $fa;
            font-size: 14px;
            color: $black;
            display: block;
            opacity: 0;
            transition: opacity .3s ease;
        }

        &:checked {
            &::before {
                opacity: 1;
            }
        }
    }

    &_center & {
        &__input {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &_gray & {

        &__label {
            color: $gray;

            a {
                &::before,
                &::after  {
                    background: $gray;
                }

                &:hover {
                    color: $gray;

                    &::before,
                    &::after {
                        background: $gray;
                    }
                }
            }
        }

        &__input {
            background: $silver;
        }
    }
}
.radio {
    position: relative;
    display: flex;
    box-sizing: border-box;

    * {
        transition: $default_transition;
    }

    &__input-wrap {
        display: flex;
        align-items: center;
    }

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        display: block;
        box-sizing: border-box;
        border-radius: 50%;
        opacity: 0;
        cursor: pointer;
    }

    &__label {
        position: relative;
        width: 24px;
        height: 24px;
        z-index: 1;
        display: block;
        background-color: $radio;
        border-radius: 50%;
        box-sizing: border-box;
        margin-right: 15px;

        &:before {
            content: '';

            @include absolute;

            width: 10px;
            height: 10px;
            background: $persian_green2;
            transform: scale(0);
            border-radius: 50%;
            transition: transform .3s ease;
        }
    }

    &__input {
        &:checked {
            + .radio__label {
                &::before {
                    transform: scale(1);
                }

                //border: 2px solid orange;
                //font-size: 10px;
                //color: orange;

            }

            &._incorrect {
                + .radio__label {
                    //border: 2px solid red;
                    //font-size: 10px;
                    //color: red;

                }
            }

            &._correct {
                + .radio__label {
                    //border: 2px solid green;
                    //font-size: 10px;
                    //color: green;

                }
            }
        }

        &:disabled {
            + .radio__label {
                opacity: 0.7;
            }
        }

        //&:checked:disabled {
        //    +.radio__label {
        //
        //    }
        //}

    }

    @media #{$tablet} {
        font-size: 14px;
    }
}

.form {
    + .attention {
        margin-top: 0;
    }

    &_flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &_flex > &__col {
        margin-bottom: 40px;
    }

    .btn-row {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        margin-top: 20px;

        .btn-style {
            margin-top: 0;
            margin-bottom: 0;

            & + .btn-style {
                margin-left: 20px;
            }
        }
    }

    .select-wrapper {
        width: 100%;
    }

    .inputs-row {
        height: 42px;
    }

    &._disable {
        &::before {
            content: '';

            @include absolute;

            width: 100%;
            height: 100%;
            z-index: 8;
        }

        & > *:not(button) {
            transition: opacity 1s ease;
            opacity: .5;
        }
    }

    &__forgot-pass {
        margin: 10px 0 15px;
    }

    &__select._active ~ &__inner {
        transform: rotateX(0deg);
        top: calc(100% + 20px);
        box-shadow: 0 5px 13px rgba(0, 0, 0, 0.5);
    }

    &__inner {
        z-index: 30;
        width: 100%;
        position: absolute;
        background: $white;
        border-bottom: 1px solid #d1d1d1;
        top: 100%;
        left: 0;
        box-sizing: border-box;
        transition: all 1s ease;
        transform: rotateX(90deg);
        transform-origin: 0 0;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0);

        .scroll-wrap {
            display: block;
            height: 100%;
            width: 100%;
            overflow: hidden;
            position: relative;
            max-height: 200px;

            & > div {
                overflow: hidden;
            }
        }

        .option {
            width: 100%;
            height: 40px;
            display: block;
            font-weight: 400;
            line-height: 40px;
            margin-right: 0;
            transition: 0.3s ease;
            box-sizing: border-box;
            text-align: left;
            padding: 0 20px;
            cursor: pointer;
            white-space: nowrap;
            color: $grey_white;

            &:nth-child(odd) {
                background: $grey_white;
            }

            &:hover,
            &._active {
                color: $black;
            }

            .type {
                margin-left: 10px;
                font-size: 12px;
                opacity: .6;
            }
        }
    }

    &._add-scroll-space {
        padding: 0 6px;
        box-sizing: border-box;
    }

    .inf {
        margin: 30px 0;
    }

    &__or {
        text-align: center;
        text-transform: lowercase;
        margin: 20px 0;
        font-size: 24px;
    }

    &__table {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__cell {
        width: calc(50% - 10px);
    }

    &__row {
        width: 100%;
        position: relative;
        display: flex;
        vertical-align: top;
        margin-top: 40px;
        justify-content: space-between;

        &_confidentiality {
            margin-top: 20px;
            text-align: center;
            justify-content: flex-start;
        }

        &_radio {
            justify-content: flex-start;

            .radio {
                margin-left: 55px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    &__row_3-col &__col {
        width: calc(100% / 3 - 40px /3);
    }

    &__row.upload-file-row &__col {
        width: calc(100% / 3 - 40px /3);
        margin-left: 20px;
        display: flex;
        align-items: flex-start;

        &:first-child {
            margin-left: 0;
        }
    }

    &__col-label {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        text-align: left;
        color: $input-gray;
        font-size: 14px;
        margin-bottom: 5px;
    }

    &__col {
        width: calc(50% - 10px);
        display: flex;
        justify-content: space-between;
        position: relative;

        &_column {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }

    &__col_1-3,
    &__row_3-col &__col {
        width: calc(100% / 3 - 40px /3);
    }

    &__col_1-2,
    &__row_2-col &__col {
        width: calc(50% - 10px);
    }

    &__col_full {
        width: 100%;
    }

    &__col_2-3 {
        width: calc(100% / 3 * 2 - 40px / 6);
    }

    &__col_1-5 {
        width: calc(20% - 10px);
    }

    &__col_3-5 {
        width: calc(60% - 10px);
    }

    &__col_4-12 {
        width: calc(100% / 3 - 10px);
    }

    &__col_8-12 {
        width: calc(100% / 12 * 8 - 10px);
    }

    .radio-wrap {
        justify-content: flex-start;
        .radio {
            margin-left: 55px;

            &:first-child {
                margin-left: 0;
            }
        }

        @media #{$pre-mobile} {
            .radio {
                margin-left: 15px;
                margin-right: 0;

                &__label {
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }

                &__text {
                    font-size: 12px;
                }
            }

        }
    }

    &__placeholder {
        font-weight: 300;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 11;
        font-size: 18px;
        color: $input-gray;
        cursor: default;
        transition: $default_transition;
        left: 20px;
    }

    &__select ~ &__placeholder {
        z-index: 1;
    }

    &__label {
        @include absolute;

        z-index: 15;
        cursor: text;
    }

    &__select-wrap {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        background: $input-gray;
    }

    &__select-wrap_menu &__inner {
        a {
            white-space: normal;
            line-height: 1.2;
        }

        .option {
            height: auto;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    &__input {
        background: transparent;
    }

    &__input,
    &__select {
        position: relative;
        z-index: 9;
        transition: $default_transition;
        height: 60px;
        background-position: 19px center;
        background-repeat: no-repeat;
        width: 100%;
        box-sizing: border-box;
        display: inline-block;
        overflow: hidden;
        padding: 0 20px;

        &:focus,
        &._active,
        &._selected {
            color: $black;

            ~ .form__placeholder {
                top: 0;
                transform: translateY(-100%);
                color: $input-gray;
            }

            ~ .form__label {
                height: 0;
            }
        }

        &.error {
            border-bottom-color: red;
        }

        &.valid {
            border-bottom-color: mediumseagreen;
        }
    }

    .error-text {
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 300;
        color: red;
        display: none;

        @include absolute(auto, auto, -5px);

        transform: translateY(100%);
    }

    .error ~ .error-text {
        display: block;
    }

    &__select {
        cursor: pointer;
        display: flex;
        align-items: center;

        &::after {
            content: '\f107';
            font-family: $fa;
            font-size: 18px;
            color: $persian_green;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: transform 1s ease, color 1s ease;

            @include absolute(0, 20px, 0, auto);
        }

        &._active {
            &::after {
                transform: rotateX(180deg);
            }
        }
    }

    &_search {
        position: relative;
        padding: 0 20px;
        width: auto;
        display: flex;
        flex: 1 auto;

        .search-button {
            background: green;
        }

        .checkbox {
            min-width: 145px;
        }
    }

    &_search & {
        &__col {
            flex: 1 auto;
        }
    }

    @media #{$pre-mobile} {
        &__cell {
            width: 100%;
        }

        &__row_3-col {
            flex-wrap: wrap;
        }

        &__row_3-col & {
            &__col {
                width: 100%;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &_profile & {
            &__row {
                flex-wrap: wrap;
            }

            &__col {
                width: 100%;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @media #{$tablet} {
        &_flex > &__col {
            margin-bottom: 25px;
        }

        &__col_1-3,
        &__row_1-3-col &__col,
        &__col_2-3,
        &__row_2-3-col &__col {
            width: calc(50% - 10px);
        }
    }
    @media #{$pre-mobile} {
        &_flex > &__col {
            margin-bottom: 18px;
        }

        &__col {
            width: 100%;
        }

        &__col_1-5 {
            width: calc(50% - 10px);
        }
    }
}

.form-label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;

    p {
        width: 100%;
        color: $black;
        text-align: center;
    }

    a {
        color: $persian_green;

        &:hover {
            text-decoration: underline;
        }
    }
}

.input-style {
    position: relative;
    border-radius: 5px;
    width: 100%;

    &_require-icon,
    &_check-icon {
        &::before {
            @include absolute(0, 10px, 0, auto);

            color: $black;
            display: flex;
            align-items: center;
            z-index: 10;
            font-family: $fa;
        }
    }

    &_require-icon {
        &::before {
            content: '\f069';
            font-size: 8px;
        }
    }

    &_check-icon {
        &::before {
            content: '\f00c';
            font-size: 14px;
        }
    }

    &__input {
        position: relative;
        z-index: 9;
        transition: $default_transition;
        background-color: $grey_white;
        box-sizing: border-box;
        height: 57px;
        width: 100%;
        padding: 0 20px;
        color: $input-gray;
        font-family: $rb;
        font-size: 16px;
        font-weight: 300;
    }

    &__input:focus ~ &,
    &__input._active ~ & {
        &__placeholder {
            top: 0;
            transform: translateY(-100%);
            font-size: 14px;
            color: $input-gray;
        }

        &__label {
            height: 0;
        }
    }

    &__input_comment {
        resize: none;
        overflow: hidden;
        padding-top: 15px;
        transition: none;
        height: 150px;
        width: 100%;

        & ~ .input-style {
            &__placeholder {
                top: 20px;
            }
        }
    }

    &__placeholder {
        font-weight: 300;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 11;
        font-size: 14px;
        color: #979797;
        cursor: default;
        transition: $default_transition;
        left: 20px;

        @media #{$mobile} {
            font-size: 16px;
        }
    }

    &__label {
        @include absolute;

        z-index: 15;
        cursor: text;
        color: $input-gray;
        font-size: 16px;
        font-weight: 300;
    }

    &.error {
        &::-webkit-input-placeholder {
            color: $input-gray;
        }

        &::-moz-placeholder {
            color: $input-gray;
        }

        &:-ms-input-placeholder {
            color: $input-gray;
        }

        &:-moz-placeholder {
            color: $input-gray;
        }
    }

    @media #{$tablet} {
        &__input:not(textarea) {
            height: 50px;
        }
    }

    @media #{$pre-mobile} {
        &__placeholder {
            font-size: 14px;
        }

        &__input {
            font-size: 14px;
        }

        &__input:not(textarea) {
            height: 38px;
        }

        &__input:focus ~ &,
        &__input._active ~ & {
            &__placeholder {
                font-size: 11px;
            }
        }

    }
}

.select-wrapper {
    position: relative;
    cursor: pointer;
    border-radius: 5px;

    &__input,
    &__select {
        position: relative;
        z-index: 9;
        transition: $default_transition;
        height: 57px;
        background-position: 19px center;
        background-repeat: no-repeat;
        width: 100%;
        box-sizing: border-box;
        display: block;
        overflow: hidden;
        padding: 0 60px 0 20px;

        &:focus,
        &._active,
        &._selected {
            color: $black;

            ~ .select-wrapper__placeholder {
                top: 0;
                transform: translateY(-124%);
            }

            ~ .select-wrapper__label {
                height: 0;
            }
        }

        &.error {
            border-bottom-color: red;
        }

        &.valid {
            border-bottom-color: mediumseagreen;
        }
    }

    &:hover & {
        &__select {
            background-color: $grey_horse;
        }
    }

    .pseudo-hidden.error + * {
        border-bottom: 2px solid red;
    }

    .pseudo-hidden.valid + * {
        border-bottom: 2px solid mediumseagreen;
    }

    &__select {
        z-index: 0;
        border-radius: 5px;
        background-color: $radio;
        &._active {
            z-index: 10;
        }
        &:focus,
        &._active,
        &._selected {

            background-color: $radio !important;
        }

        &:after {
            content: "\f078";
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            width: 50px;
            height: 100%;
            color: $persian_green;
            font-family: $fa;
            font-size: 12px;
            font-weight: 400;
        }

        &._active {
            &:after {
                content: "\f077";
            }
        }

        span {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: auto;
            color: $black;
            font-size: 16px;
            font-weight: 700;
            white-space: normal;
        }
    }

    &__select._active ~ &__inner {
        transform: rotateX(0.1deg);
        top: calc(100% - 6px);
        z-index: 31;
        // box-shadow: 0 5px 13px rgba(0, 0, 0, 0.5);

    }

    &__inner {
        z-index: 30;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        box-sizing: border-box;
        transition: all 1s ease;
        transform: rotateX(90deg);
        transform-origin: 0 0;

        // box-shadow: 0 1px 5px rgba(0, 0, 0, 0);

        .scroll-wrap {
            position: relative;
            display: block;
            background-color: $radio;
            overflow: hidden;
            box-sizing: border-box;
            border-radius: 5px;
            height: 100%;
            width: 100%;
            max-height: 200px;
            padding: 20px 0;

            & > div {
                overflow: hidden;
            }
        }

        .option {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 0;
            transition: 0.3s ease;
            box-sizing: border-box;
            text-align: left;
            padding: 0 20px;
            cursor: pointer;
            white-space: nowrap;
            color: $input-gray;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                white-space: normal;
            }
            &:hover,
            &._active {
                color: $persian_green;
            }

            .type {
                margin-left: 10px;
                font-size: 12px;
                opacity: .6;
            }
        }
    }

    &__placeholder {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 11;
        color: $grey_dark;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.2px;
        cursor: default;
        transition: $default_transition;
        left: 20px;

        @media #{$pre-mobile} {
            font-size: 12px;
        }
    }

    &__select ~ &__placeholder {
        z-index: 1;
    }

    &__label {
        @include absolute;

        z-index: 15;
        cursor: text;
    }

    &_transp & {
        &__placeholder {
            top: 50% !important;
        }

        &__select {
            background-color: transparent;

            &:focus,
            &._active,
            &._selected {
                ~ .select-wrapper__placeholder {
                    top: 0 !important;
                }
            }
        }
    }

    @media #{$pre-mobile} {
        &__select span {
            font-size: 12px;
        }

        &__inner .option {
            font-size: 12px;
        }
    }
}

.inline-wrapper {
    text-align: justify;
    text-justify: newspaper;
    zoom: 1;
    line-height: 0;
    font-size: 1px;

    > div {
        display: inline-block;
        vertical-align: top;
    }

    &:after {
        content: "";
        display: inline-block;
        overflow: hidden;
        visibility: hidden;
        width: 100%;
        height: 0;
    }
}

.center_img {
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        display: block;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
    }
}

.soc-icons {
    display: flex;
    align-items: center;

    &__icon {
        width: 50px;
        height: 50px;
        background: green;
        border: none;
        margin: 0 5px;
        cursor: pointer;
        transition: opacity .3s ease;

        &:hover {
            opacity: .7;
            border: none;
        }
    }
}

.items-list {
    display: flex;
    justify-content: space-between;
    text-align: center;

    &__item {
        width: 30%;
    }

    &__img-wrap {
        margin: auto;
    }

    &__text {
        font-size: 16px;
        line-height: 1.2;
    }

    @media #{$tablet} {
        &__text {
            font-size: 14px;
        }
    }
}

.sticky {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: #848484;

    &__container {
        width: 500px;
        text-align: center;
        display: block;
        position: relative;
        background: #676767;

        &._sticky {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            margin: auto;
            z-index: 20;
        }
    }
}

.parallax {
    &__wrap {
        width: 100%;
        height: 300px;
        position: relative;
        background: #b9b9b9;
    }

    &__content {
        width: 50%;
        height: 50%;
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: green;
    }
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    &._show {
        z-index: 85;
    }

    &:not(._show) {
        //z-index: -999;
        pointer-events: none;
    }

    &._show & {
        &__bg {
            transform: translateX(0);
        }
    }

    &__bg {
        position: absolute;
        height: 100vh;
        width: 100vw;
        transition: 1s ease;
        background: rgba(255, 255, 255, 0.4);

        &_left {
            transform: translateX(-150%);
        }

        &_right {
            transform: translateX(150%);
        }
    }
}

.popups-wrap {
    display: block;

    @include absolute;

    height: 0;
    width: 100%;

    .container {
        position: relative;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.popup {
    z-index: -90;
    perspective: 1000px;
    position: fixed;
    display: none;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -110vh);
    transition: transform 1s ease;
    animation: none;
    max-width: 650px;
    text-align: center;

    .form {
        &__row_3-col {
            .form__col {
                width: calc(100% / 3 - 40px / 3);
            }
        }

        &__row_2-col {
            .form__col {
                width: calc(50% - 10px);
            }
        }
    }

    &._show {
        display: block;
    }

    &._animate {
        transform: translate(-50%, -50%);
        z-index: 999;
    }

    .close-button {
        transform: translateY(-100%);
        background: $black;

        &::before,
        &::after {
            background: $white;
        }

        @media #{$mobile} {
            transform: translateY(0);
            background: transparent;

            &::before,
            &::after {
                background: $gray;
            }
        }
    }

    &__content {
        height: calc(100% - 50px);
        width: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        padding: 50px 20px;
        max-width: 1000px;
        margin: auto;

        p {
            font-size: 14px;
            line-height: 1.8;

            &.bold {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 0;
            }
        }

        .scroll-container {
            display: block;
            position: relative;
        }

        .scroll-wrap {
            display: block;
            position: relative;
            height: calc(100% - 165px);
            overflow: hidden;
            max-width: 790px;
            margin: auto;
            margin-right: 15px;
            padding-right: 10px;
            box-sizing: border-box;
        }
    }

    &_text & {
        &__content {
            height: calc(100% - 230px);
            top: 230px;
        }

        &__title {
            height: 230px;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        background: $white;
        transform: rotateX(81deg);
        transform-origin: 0 0;
        opacity: 0;
        transition: transform 1s ease, opacity .5s ease, top .5s ease;
        margin-top: 50px;

        @media #{$mobile} {
            margin-top: 0;
        }
    }

    &_center {
        position: fixed;
        top: -100vh;
        left: 50%;
        transform: translate(-50%, -50%);

        &._animate {
            top: 50%;
        }
    }

    &._animate {
        animation: show-popup 0s linear both;
        animation-delay: 0s;
    }

    &._animate & {
        &__wrapper {
            transform: rotateX(0deg);
            opacity: 1;
        }
    }

    &_ty {
        h3 {
            font-size: 80px;
            padding-bottom: 20px;
            color: $gray;
        }
    }

    &_ty & {
        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 100%;
            top: 0;

            p {
                font-size: 36px;
                line-height: 1.4;
                font-weight: 300;
                text-align: left;
                color: $gray;
            }
        }
    }
}

.close-button {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    left: auto;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 10;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 50%;
        height: 2px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        background: $black;
        transition: transform .5s ease;
        will-change: transform;

        @media #{$mobile} {
            height: 2px;
        }
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }

    &:hover {
        &::before {
            transform: rotate(-225deg);
        }

        &::after {
            transform: rotate(45deg);
        }
    }
}

#ytvideo {
    width: 100%;
}

.ps-container {
    .ps-scrollbar-y-rail {
        opacity: 1 !important;
        width: 5px !important;
        background: transparent !important;

        .ps-scrollbar-y {
            right: 0 !important;
            background: $persian_green !important;
            border-radius: 0 !important;
            width: 5px !important;
            cursor: pointer !important;
        }
    }

    &:hover {
        .ps-scrollbar-y-rail {
            opacity: 1 !important;
        }
    }
}

.confidentiality {
    max-width: 450px;
    text-align: center;
    margin: 20px auto;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 300;

    &._right {
        text-align: right;
        margin-right: 0;
    }

    a {
        color: $persian_green;

        &:hover {
            text-decoration: underline;
        }
    }

    @media #{$pre-mobile} {
        a {
            display: inline;
        }
    }
}

.pseudo-hidden {
    position: absolute;
    left: -200vw;
    opacity: 0;
    z-index: -999;
    pointer-events: none;
    overflow: hidden;
    transform: scale(0);
}

.sorting {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 50px;
    z-index: 15;

    &::before {
        content: '';

        @include absolute;

        width: 100%;
        height: 1px;
        background: $grey-light;
    }

    .select-wrapper {
        max-width: 375px;
        margin-left: auto;
        margin-right: 0;
    }
}

.breadcrumbs {
    background-color: $grey_white;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
        color: $persian_green;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.1px;
        margin-right: 3px;
        transition: color .5s ease;

        &:hover {
            color: $black;
        }

        &:after {
            content: '\f105';
            font-family: $fa;
            color: $black;
            font-size: inherit;
            display: inline-block;
            margin-left: 7px;
        }
    }

    span {
        color: $black;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.1px;
    }

    .bread-icon {
        font-size: 14px;
    }

	@media #{$pre-mobile} {
        padding: 10px 0;
        height: auto;
	}
}

.pagination {
    width: 100%;
    padding-top: 75px;
    margin-bottom: 95px;

    @media #{$mobile} {
        padding-top: 20px;
        margin-bottom: 40px;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
    }

    &__link {
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 2px;
        cursor: pointer;
        transition: all .3s ease;
        font-size: 20px;
        font-weight: 800;
        color: $gray;
        background: transparent;

        &:hover,
        &._active {
            color: green;
        }

        &_prev {
            margin-right: 10px;

            &::before {
                content: '\f104';
            }
        }

        &_next {
            margin-left: 10px;

            &::before {
                content: '\f105';
            }
        }

        &_prev,
        &_next {
            &::before {
                font-family: $fa;
                display: block;
                position: relative;
                font-size: inherit;
                color: inherit;
            }
        }
    }
}

@keyframes linesanimation1 {
    0%,
    50%,
    100% {
        transform: translateY(0px) translateX(0px) skew(-37deg);
    }

    30%,
    80% {
        transform: translateY(3px) translateX(3px) skew(-37deg);
    }
}

@keyframes linesanimation2 {
    0%,
    50%,
    100% {
        transform: translateY(0px) translateX(0px);
    }

    30%,
    80% {
        transform: translateY(3px) translateX(3px);
    }
}

.bg-lines {
    @include absolute(auto);
    display: flex;
    height: 108%;
    width: 80%;
    transform: skew(-37deg);
    background: $smoke;
    animation: linesanimation1 6s ease-in-out alternate-reverse infinite;

    &::before,
    &::after {
        content: '';
    }

    &::before {
        @include absolute(-14%, auto, auto, -60%);

        height: 37%;
        width: 10%;
        background: $orange;
        animation: linesanimation2 4s ease-in-out alternate-reverse infinite;
    }

    &::after {
        @include absolute(auto, -35%, -12%, auto);

        width: 20%;
        height: 70%;
        background: $turquoise;
        animation: linesanimation2 5s ease-in-out alternate-reverse infinite;
    }
}

.simple-banner {
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 450px;

    &__img {
        position: relative;
        height: 100%;
        width: auto;
    }

    @media #{$tablet} {
        height: 400px;

        .img {
            height: 400px;
        }
    }

    @media #{$pre-mobile} {
        height: 224px;

        .img {
            height: 224px;
        }
    }
}

.upload-wrap {
    margin-top: 0;
}

.btn-style {
    .file-upload {
        z-index: 10;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        opacity: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
    }

    &_file-upload & {
        &__label {
            display: flex;
            align-items: center;

            &::before {
                content: '\f0c6';
                font-family: $fa;
                font-size: 28px;
                color: inherit;
                margin-right: 8px;
                transform: scale(1, -1);
                font-weight: 300;
            }
        }
    }

    &_filter & {
        &__label {
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
                content: '';
                font-family: $fa;
                font-size: inherit;
                color: inherit;
                display: inline-block;
                vertical-align: baseline;
                margin-right: 10px;
				background: url('../images/svg/filter-results-button.svg') no-repeat center center;
				background-size: contain;
				width: 33px;
				height: 23px;
            }
        }
    }

    &_filter {
        @media #{$tablet} {
            height: 40px;
        }
    }
}

.upload-file-row {
    text-align: left;
    justify-content: space-between;

    &.mb0 {
        margin-bottom: 0;
    }

    .input-file-wrapper {
        width: 100%;
    }

    .files-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: self-start;
        justify-content: center;
    }

    .file-name {
        padding-right: 35px;
        color: $input-gray;

        .close-button {
            &::before,
            &::after {
                background: $gray-med;
            }
        }
    }

    @media #{$tablet} {
        flex-direction: column;

        .form__col {
            width: 100%;
        }

        .btn-style {
            height: 50px;
        }

        .files-list {
            text-align: center;
            align-items: center;
            margin-top: 10px;
        }

        .file-name {
            font-size: 16px;
            font-weight: 300;
        }
    }

    @media #{$pre-mobile} {
        height: 38px;
    }
}

.inner-block {
    &__title {
        font-family: $rb;
        font-size: 16px;
        font-weight: 400;
        color: $persian_green;
        display: flex;
        align-items: center;
        cursor: pointer;

        &::after {
            content: '\f107';
            font-family: $fa;
            font-size: inherit;
            color: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: transform 1s ease, color 1s ease;
            position: relative;
            margin-left: 10px;
        }

        &._active {
            &::after {
                transform: rotateX(180deg);
            }
        }
    }

    &__inner {
        display: none;
        margin-top: 30px;
    }

    @media #{$tablet} {
        &__title {
            font-size: 14px;
        }
    }

    @media #{$pre-mobile} {
        &__title {
            justify-content: center;
            font-size: 12px;
        }
    }
}

.captcha-table {
    margin-top: 40px;
    display: flex;
    align-items: center;

    &__col {
        width: 50%;

        &_captcha-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    @media #{$pre-mobile} {
        margin-top: 15px;
        flex-wrap: wrap;

        &__col {
            width: 100%;

            &_captcha-wrap {
                margin-top: 40px;
                margin-bottom: 30px;
                justify-content: center;
            }
        }
    }
}

.label {
    display: flex;
    font-family: $rb;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 20px;

    span {

    }

    a {
        color: $persian_green;
        transition: opaity .3s ease;
        margin-left: 10px;

        &:hover {
            opacity: .5;
        }
    }

    @media #{$pre-mobile} {
        font-size: 12px;
    }
}

.currency {
	display: flex;
	align-items: baseline;
	justify-content: flex-start;

	&::after {
		content: attr(data-currency);
		display: block;
		position: relative;
		margin-left: 10px;
	}
}

.error-message {
    color: #e60000;
    display: none;
}

.error + .error-message {
    display: block;
}

.animate-up {
    transform: translateY(100px);
    opacity: 0;

    &._animate {
        transition: opacity 1s ease, transform 1s ease;
        transform: translateY(0%);
        opacity: 1;
    }
}
.animate-down {
    transform: translateY(-100px);
    opacity: 0;

    &._animate {
        transition: opacity 1s ease, transform 1s ease;
        transform: translateY(0%);
        opacity: 1;
    }
}
.animate-right {
    transform: translateX(100px);
    opacity: 0;

    &._animate {
        transition: opacity 1s ease, transform 1s ease;
        transform: translateX(0%);
        opacity: 1;
    }
}
.animate-left {
    transform: translateX(-100px);
    opacity: 0;

    &._animate {
		transition: opacity 1s ease, transform 1s ease;
        transform: translateX(0%);
        opacity: 1;
    }
}

$bar-width: 21px;
$bar-height: 3px;
$bar-spacing: 7px;

.hamburger-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
    height: 50px;
    width: 40px;
    z-index: 801;

    &__hamburger {
        width: 29px;
        height: $bar-height + $bar-spacing * 2;
        position: relative;
        z-index: 1;
        cursor: pointer;
        display: block;
        transition: 0.4s ease-in-out;
    }

    &__bar {
        width: $bar-width;
    }

    &__bar:before {
        width: $bar-width;
    }

    &__bar:after {
        width: $bar-width;
    }

    &__bar, &__bar:after, &__bar:before {

        height: $bar-height;

        will-change: transform;
    }

    &__bar {
        position: relative;
        transform: translateY($bar-spacing);
        background: $persian_green;
        transition: all 0ms 300ms;
        margin: auto;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: $bar-spacing;
            background: $persian_green;
            transition: width 0ms 300ms, bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: $bar-spacing;
            background: $persian_green;
            transition: width 0ms 300ms, top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
        }
    }

    &._active & {
        &__bar {
            background-color: transparent;
            width: $bar-width;

            &::after,
            &::before {
                width: $bar-width;
            }

            &:after {
                top: 0;
                transform: rotate(45deg);
                transition: width 0ms 300ms, top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
            }

            &:before {
                bottom: 0;
                transform: rotate(-45deg);
                transition: width 0ms 300ms, bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
            }
        }

        &__label {
            .help {
                display: none;
            }
            .close {
                display: block;
            }
        }
    }
}

.recall-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media #{$pre-mobile} {
        flex-direction: column;

        label {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }
}