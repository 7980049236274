.contacts {
    background-color: $white;
    padding: 39px 0;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 36px;
    }

    &__col {
        &_left {
            width: calc(100% / 3);
        }
    }

    @media #{$tablet} {
        &__wrapper {
            margin-top: 25px;
        }

        &__col {
            &_left {
                width: calc(100% / 12 * 5);
                padding-right: 20px;
                box-sizing: border-box;
            }
        }
    }

    @media #{$pre-mobile} {
        padding: 39px 0 0;

        &__wrapper {
            flex-wrap: wrap;
        }

        &__col {
            width: 100%;
            padding: 0;
        }

        &__inner {
        }

        &__map {
        }
    }
}

.contact-list {
    $list: &;

    &__item {
        transition: color .3s ease;
        display: flex;
        align-items: flex-start;
        margin-bottom: 26px;
        color: $black;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a.contact-list__item:hover {
        color: $turquoise;
    }

    &__icon {
        display: block;
        width: 20px;
        margin-right: 24px;
        font-size: 25px;
        color: inherit;
        text-align: left;

        &.icon-placeholder-2 {
            font-size: 29px;
        }

        &.icon-contact {
            font-size: 23px;
        }
    }

    &__text {
        color: inherit;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
    }

    @media #{$tablet} {
        &__text {
            font-size: 14px;
        }

        &__icon {

            width: auto;
            margin-right: 26px;
        }

        &__item {
            margin-bottom: 15px;
        }
    }

    @media #{$pre-mobile} {
        width: calc(100% - 55px);
        margin: auto;

        &__icon {
            margin-right: 18px;
        }
    }
}

.map-wrapper {
    position: relative;
    width: calc(100% / 12 * 7 - 7px);
    height: 360px;
    margin-top: 6px;

    &__inner {
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        background-color: #e9eae9;
        width: calc(100vw / 2 + (100% / 12 * 2) - 20px);
        height: 100%;
        transform: translateX(100%);
        opacity: 0;

        &._animate {
            //transition-delay: .5s;
			transition: opacity 1s ease, transform 1s ease;
            transform: translateX(0%);
            opacity: 1;
        }
    }

    &__map {
        position: absolute;
        right: 0;
        top: 0;
        width: calc(100% + 35%);
        height: calc(100% + 30%);
    }

    @media #{$tablet} {
        height: 265px;
        width: calc(100% / 12 * 7);

        &__inner {
            width: calc(100vw / 2 + (100% / 12 * 2));
        }

        &__map {
            width: calc(100% + 88px);
        }
    }

    @media #{$pre-mobile} {
        width: 100%;
        margin-top: 35px;

        &__inner {
            width: calc(100% + 20px);
            left: -10px;
        }

        &__map {
            left: 0;
            right: auto;
            width: calc(100% + 88px);
        }
    }
}